import { Injectable } from '@angular/core';
import { setProps } from '@ngneat/elf';
import { combineLatest, map, Subject, tap } from 'rxjs';

import { DesignerMode, OutlineType, PanelType } from '~shared/enums';
import { Outline, IArticle, IParameterValue } from '~shared/types';
import { GenericItem, } from '~shared/components/cabinet-builder/engine-render.service';
import { SelectionType } from '~shared/shared.types';
import { SelectionManager } from '~shared/components/cabinet-builder/selectionManager';

import { editorStore } from './editor.store';
import { editorSelector } from './editor.selectors';
import { EditorMode } from './editor.types';

@Injectable()
export class EditorRepository {
	public activeConfigurationPanel$ = editorSelector.activeConfigurationPanel$;
	public outlineMap$ = editorSelector.outlineMap$;
	public allConfig$ = editorSelector.allConfig$;
	public designerMode$ = editorSelector.designerMode$;
	public openDoorIds$ = editorSelector.openDoorIds$;
	public partEditorFullscreen$ = editorSelector.partEditorFullscreen$;
	public transparentPanelTypes$ = editorSelector.transparentPanelTypes$;
	public partEditorSelectedItem$ = editorSelector.partEditorSelectedItem$;
	public selectedItems$ = editorSelector.selectedItems$;
	public selectedFaceIdx$ = editorSelector.selectedFaceIdx$;
	public dimensions$ = editorSelector.dimensions$;
	public mode$ = editorSelector.mode$;
	public isolatedItem$ = editorSelector.isolatedItem$;
	public fillerHighlight$ = editorSelector.fillerHighlight$;
	public disableFields$ = combineLatest([editorSelector.loading$, editorSelector.locked$])
		.pipe(map(([loading, locked]) => loading || locked));

	public selectedArticleZones$ = editorSelector.selectedItems$
		.pipe(
			map((items) => items.filter((item) => SelectionManager.getSelectionType(item) === SelectionType.ARTICLE_ZONE)),
			map((items) => items.map((item) => item.articleZone))
		);
	public selectedArticles$ = editorSelector.selectedItems$
		.pipe(
			map((items) => items.filter((item) => SelectionManager.getSelectionType(item) === SelectionType.ARTICLE)),
			map((items) => items.map((item) => item.article))
		);
	public selectedDoors$ = editorSelector.selectedItems$
		.pipe(
			map((items) => items.filter((item) => SelectionManager.getSelectionType(item) === SelectionType.DOOR)),
			map((items) => items.map((item) => item))
		)

	public updateRequired$: Subject<boolean> = new Subject();
	public triggerCabinetCreation$: Subject<boolean> = new Subject();

	public setActiveConfigurationPanel(activeConfigurationPanel: string): void {
		editorStore.update(
			setProps({
				activeConfigurationPanel
			})
		);
	}

	public setPartEditorFullscreen(partEditorFullscreen: boolean): void {
		editorStore.update(
			setProps({
				partEditorFullscreen
			})
		);
	}

	public setSelectedFaceIdx(selectedFaceIdx: number): void {
		editorStore.update(
			setProps({
				selectedFaceIdx
			})
		);
	}

	public setSelectedItems(selectedItems: GenericItem[]): void {
		editorStore.update(
			setProps({
				selectedItems
			})
		);
	}

	public setLoading(loading: boolean): void {
		editorStore.update(
			setProps({
				loading
			})
		);
	}

	public setMode(mode: EditorMode): void {
		if (mode === EditorMode.DEFAULT) {

		}

		editorStore.update(
			setProps({
				mode
			})
		);
	}

	public setFillerHighlight(fillerHighlight: string): void {
		editorStore.update(
			setProps({
				fillerHighlight
			})
		);
	}

	public setIsolatedItem(isolatedItem: GenericItem): void {
		editorStore.update(
			setProps({
				isolatedItem
			})
		);
	}

	public setLocked(locked: boolean): void {
		editorStore.update(
			setProps({
				locked
			})
		);
	}

	public setDesignerMode(designerMode: DesignerMode): void {
		editorStore.update(
			setProps({
				designerMode
			})
		);
	}

	public setPartEditorSelectedItem(partEditorSelectedItem: string): void {
		editorStore.update(
			setProps({
				partEditorSelectedItem
			})
		);
	}

	public setOutline(outlineType: OutlineType, outline: Outline): void {
		editorStore.update(
			setProps({
				outlineMap: {
					...editorStore.getValue().outlineMap,
					[outlineType]: outline
				}
			})
		);
	}

	public setOutlineMap(outlineMap: Record<OutlineType, Outline>): void {
		editorStore.update(
			setProps({
				outlineMap
			})
		);
	}

	public setDimensions(newDimensions: Record<string, string | number>): void {
		editorStore.update(
			setProps(({ dimensions }) => ({
				dimensions: {
					...dimensions,
					...newDimensions
				}
			}))
		);
	}

	public upsertArticle(articleId: string, article: Partial<IArticle>): void {
		editorStore.update(
			setProps({
				articles: [
					...editorStore.getValue().articles.filter(({ id }) => id !== article.id),
					article
				],
			})
		);
	}

	public upsertPanelParameter(articleId: string, panelId: string, parameter: Partial<IParameterValue>): void {
		editorStore.update(
			setProps({
				parameters: [
					...editorStore.getValue().parameters.filter((p) => p.id !== panelId),
					{
						...parameter,
						id: panelId,
						articleId
					}
				],
			})
		);
	}

	public clearArticles(): void {
		editorStore.update(
			setProps({
				articles: [],
			})
		);
	}

	public setOpenDoorsIds(openDoorIds: string[]): void {
		editorStore.update(
			setProps({
				openDoorIds,
			})
		);
	}

	public setTransparency(transparentPanelTypes: PanelType[]): void {
		editorStore.update(
			setProps({
				transparentPanelTypes,
			})
		);
	}

	public deleteArticle(articleId: string): void {
		editorStore.update(
			setProps({
				articles: editorStore.getValue().articles.filter(({ id }) => id !== articleId),
			})
		);
	}
}
