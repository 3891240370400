import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	SimpleChanges,
	Output,
	ViewChild, NgZone, OnChanges
} from '@angular/core';
import {
	combineLatest, debounceTime,
	distinctUntilChanged, forkJoin,
	Observable,
	pairwise,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';

import {Item, Part, ShapeInfo} from '~shared/types';
import {SceneTypeEnum, ThreeObjectTypeEnum} from '~shared/enums';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';

import { PartEditorRepository } from '~modules/projects/store/part-editor/part-editor.repository';
import {CameraManager} from "~shared/components/cabinet-builder/managers/cameraManager";
import {SceneManager} from "~shared/components/cabinet-builder/managers/sceneManager";
import {ControlsManager} from "~shared/components/cabinet-builder/managers/controlsManager";
import {ConfigurationManager} from "~shared/components/cabinet-builder/managers/configurationManager";
import {TweenManager} from "~shared/components/cabinet-builder/managers/tweenManager";
import {Measurements} from "~shared/components/cabinet-builder/descoped/measurements";
import {SelectionManager} from "~shared/components/cabinet-builder/managers/selectionManager";
import {ObjectBuilder} from "~shared/components/cabinet-builder/objectBuilder";
import {PartsRepository} from "~modules/projects/store/parts/parts.repository";

import {equals} from 'ramda';
import {GeneralItemEditor, IBuildPartItemProps} from "~shared/components/cabinet-builder/generalItemEditor";


@Component({
	selector: 'app-part-editor',
	templateUrl: './part-editor.component.html',
})
export class PartEditorCanvasComponent
	implements OnInit, OnDestroy, AfterViewInit, OnChanges {
	@Input() part: Part;
	@Input() item: Item;

	@Output() public toggleFullscreen = new EventEmitter();

	@ViewChild('container', { read: ElementRef }) containerRef: ElementRef;

	public buttons$: Observable<
		{ type: string; icon?: string; action?: string }[]
	>;
	public showDivider = false;
	public selectedId: string;
	public shapeInfo: Array<ShapeInfo>;
	public showDoor = false;
	public activeItem$: Observable<Item>;
	public fullscreen$: Observable<boolean>;
	public loading = true;
	public history = [];
	public partHasNoItems = false;
	items: Item[] = [];

	private componentDestroyed$: Subject<boolean> = new Subject();
	private partDestroyed$: Subject<boolean> = new Subject();
	partEditor: GeneralItemEditor;

	objectBuilder: ObjectBuilder;
	tweenManager: TweenManager;
	measurementsManager: Measurements;
	selectionManager: SelectionManager;
	sceneManager: SceneManager;
	cameraManager: CameraManager;
	controlsManager: ControlsManager;
	configurationManager: ConfigurationManager;
	width: number;
	height: number;
	public container: HTMLElement;
	private newItemAdded = false;
	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly partEditorRepository: PartEditorRepository,
		private zone: NgZone,
		private partsRepository: PartsRepository
	)
	{}

	public ngOnInit(): void {
		this.fullscreen$ = this.editorRepository.partEditorFullscreen$.pipe(
			takeUntil(this.componentDestroyed$),
			tap(() => {
				setTimeout(() => this.sceneManager?.resize());
			})
		);

		this.partEditorRepository.clearHistory();

		this.itemsRepository.activePartItems$
			.pipe(
				tap((items) => {
					this.loading = false;
					this.partHasNoItems = !items.length;
				}),
				takeUntil(this.componentDestroyed$)
			)
			.subscribe();
	}

	ngAfterViewInit() {
		this.itemsRepository.activePartItems$
			.pipe(distinctUntilChanged((itemPrev, itemCurr) => equals(itemPrev, itemCurr)))
			.pipe(
				tap(() => {
					if (this.sceneManager) {
						this.sceneManager.cleanSceneObjects();
					}
				}),
				tap((items) => {
					this.zone.runOutsideAngular(() => this.initialiseManagers(items));
					this.items = items;
				}),
				takeUntil(this.componentDestroyed$)
			)
			.subscribe();

		this.partEditorRepository.itemPositions$
			.pipe(
				takeUntil(this.componentDestroyed$),
			)
			.subscribe((positions) => {
				if (!positions.length || !this.partEditor) {
					return;
				}

				this.partEditor?.updateItemPositions(positions);
				this.sceneManager?.resize();
			});
	}

	initialiseManagers(items: Item[]) {
		const container = this.containerRef.nativeElement;
		if (!this.sceneManager) {
			this.init(container);
		}
		if (!this.partEditor) {
			this.initPartEditor();
		}
		this.partEditorRepository.clearHistory();
		this.partDestroyed$.next(true);
		this.partDestroyed$.complete();
		this.partDestroyed$ = new Subject();
		this.buildItem(items);
		this.partEditor.positions$.pipe(
			debounceTime(500),
			pairwise(),
			switchMap(([oldPositions, newPositions]) => {
				this.partEditorRepository.setItemPositions(newPositions);

				return combineLatest(newPositions
					.filter((position, i) => !equals(position?.position, oldPositions?.[i]?.position) || !equals(position?.rotation, oldPositions?.[i]?.rotation))
					.map((position) => this.itemsRepository
						.updateItemCoordinates(position.itemId, position.partId, {
							partCoordinate: position.position,
							partRotation: position.rotation,
						})));
			}),
			takeUntil(this.partDestroyed$)
		)
			.subscribe();
	}

	init(container: HTMLElement) {
		while (container.firstChild) {
			container.removeChild(container.firstChild);
		}

		const bounding = container.getBoundingClientRect();

		console.log(container);
		const { width, height } = bounding;

		this.container = container;

		//// Init managers
		this.cameraManager = new CameraManager();
		this.cameraManager.initializeCamera(container);
		console.log('initCamera', this.cameraManager);
		this.sceneManager = new SceneManager(this.cameraManager, this.container, SceneTypeEnum.PART_SCENE);
		this.sceneManager.setScene();
		console.log('initScene', this.sceneManager);
		this.controlsManager = new ControlsManager(this.cameraManager, this.sceneManager);
		this.configurationManager = new ConfigurationManager(this.sceneManager);

		this.measurementsManager = new Measurements(this.sceneManager.scene);
		this.selectionManager = new SelectionManager(
			this.cameraManager,
			this.sceneManager,
			this.controlsManager,
			this.editorRepository
		);
		this.objectBuilder = new ObjectBuilder(this.sceneManager);
		this.cameraManager.updateCamera(this.width, this.height);
		this.tweenManager = new TweenManager(this.cameraManager, this.sceneManager, this.controlsManager);

		this.sceneManager.initScene(this.container);
	}


	initPartEditor(disableInteraction = false) {
		this.partEditor =  new GeneralItemEditor(
			this.objectBuilder,
			this.measurementsManager,
			this.selectionManager,
			this.editorRepository,
			this.cameraManager,
			this.sceneManager,
			this.controlsManager,
			this.configurationManager,
			this.itemsRepository,
			this.tweenManager
		);

		if (!disableInteraction) {
			this.partEditor.addMovementEvents();
		}
	}

	buildItem(items: Item[]) {
		items.forEach((item: Item, i: number) => {
			this.partEditor.build({
				item: item,
				hideTimer: 100,
				hideDoors: null,
				renderView: null,
				clearPositions: i === 0} as IBuildPartItemProps);
		});
		if (!items?.length) {
			this.sceneManager.startRenderLoop();
		}
		const positions = this.partEditor.arrangeItems(this.newItemAdded, this.newItemAdded);
		this.partEditor.positionCamera();
		// if new item added
		if (positions?.length && this.newItemAdded) {
			const coordinatesUpdate$ = new Subject<void>();
		   forkJoin(
				positions.map(position => this.itemsRepository.updateItemCoordinates(position.itemId, position.partId, {
					partCoordinate: position.position,
					partRotation: position.rotation,
				})),
			 takeUntil(coordinatesUpdate$)
			).subscribe();
			coordinatesUpdate$.next();
			coordinatesUpdate$.complete();
		}

		this.newItemAdded = !!items.find(item => item.id === 'new');

		this.animateDuplicate();
	}

	ngOnChanges(changes: SimpleChanges
	) {
		if ((changes['part'] || changes['item']) && this.part && this.partEditor && !this.item) {
			setTimeout(() => {
				this.sceneManager.resize();
			});
		}
	}

	animateDuplicate(): void {
		// We have to run this outside angular zones,
		// because it could trigger heavy changeDetection cycles.
		this.zone.runOutsideAngular(() => {
			if (document.readyState !== 'loading') {
				this.sceneManager.needToRender(100);
			} else {
				window.addEventListener('DOMContentLoaded', () => {
					this.sceneManager.needToRender(100);
				});
			}

			window.addEventListener('resize', () => {

				this.sceneManager?.resize();
			});
		});
	}

	onFullscreenClick(): void {
		this.editorRepository.partEditorFullscreen$
			.pipe(take(1))
			.subscribe((fullscreen) =>
				this.editorRepository.setPartEditorFullscreen(!fullscreen)
			);
	}

	onShapeSelect(shapeInfo: Array<ShapeInfo>) {
		this.showDoor = false;
		this.showDivider = false;

		if (shapeInfo?.length === 1) {
			if (shapeInfo[0].type === ThreeObjectTypeEnum.LAYOUT) {
				this.showDivider = true;
				this.shapeInfo = shapeInfo;
			}
		}

		if (shapeInfo?.length >= 1) {
			if (
				shapeInfo.every(
					(shapeInfo) => shapeInfo.type === ThreeObjectTypeEnum.LAYOUT
				)
			) {
				this.showDoor = true;
				this.shapeInfo = shapeInfo;
			}
		}
	}


	dispose() {
		this.sceneManager?.cleanSceneObjects();
	}

	public ngOnDestroy(): void {
		// this.sceneManager.dispose();
		// this.cameraManager.disposeCamera();
		// this.controlsManager.disposeControls();
		this.editorRepository.setPartEditorSelectedItem(null);
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
