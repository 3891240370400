import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, takeUntil, filter, switchMap } from 'rxjs/operators';

import { DeliverableAddressType } from '~modules/auth/types/account.types';
import { ProjectsRepository } from '~modules/projects/store/projects/projects.repository';
import { CountryOptions } from '~shared/shared.const';
import { CustomValidators } from '~shared/validators/custom';

@Component({
	templateUrl: './detail.component.html',
})
export class DetailComponent implements OnInit, OnDestroy {
	public loading = false;
	public deliveryAddressTypeFormControl = new FormControl<DeliverableAddressType>(DeliverableAddressType.SAME, [Validators.required]);
	public projectForm: FormGroup;
	public countryOptions = CountryOptions;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly router: Router,
		private readonly projectRepository: ProjectsRepository,
		private readonly fb: UntypedFormBuilder,
		private readonly toastrService: ToastrService,
		private readonly activatedRoute: ActivatedRoute,
	) {}

	public ngOnInit(): void {
		this.projectForm = this.fb.group({
			name: [null, Validators.required]
		});

		this.projectRepository.getProject(this.activatedRoute.snapshot.params.projectId);
		this.projectRepository.project$
			.pipe(
				filter((project) => !!project),
				takeUntil(this.componentDestroyed$),
			)
			.subscribe((project) => {
				if (project.deliveryDetails) {
					this.projectForm = this.fb.group({
						name: [null, Validators.required],
						deliveryDetails: this.fb.group({
							firstname: [null, [Validators.required]],
							lastname: [null, [Validators.required]],
							telephonenumber: [null, [Validators.required, CustomValidators.validPhoneNumber]],
							street: [null, [Validators.required]],
							busNumber: [null, []],
							houseNumber: [null, [Validators.required]],
							zip: [null, [Validators.required]],
							city: [null, [Validators.required]],
							country: [null, [Validators.required]]
						})
					});

					this.deliveryAddressTypeFormControl.patchValue(DeliverableAddressType.OTHER, {
						emitEvent: false
					});
				}

				setTimeout(() => this.projectForm.patchValue(project))
			})

		this.deliveryAddressTypeFormControl.valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((deliveryAddressType) => {
				const oldValue = this.projectForm.value;

				if (deliveryAddressType === DeliverableAddressType.SAME) {
					this.projectForm = this.fb.group({
						name: [null, Validators.required]
					});

					return this.projectForm.patchValue(oldValue);
				}

				this.projectForm = this.fb.group({
					name: [null, Validators.required],
					deliveryDetails: this.fb.group({
						firstname: [null, [Validators.required]],
						lastname: [null, [Validators.required]],
						telephonenumber: [null, [Validators.required, CustomValidators.validPhoneNumber]],
						street: [null, [Validators.required]],
						busNumber: [null, []],
						houseNumber: [null, [Validators.required]],
						zip: [null, [Validators.required]],
						city: [null, [Validators.required]],
						country: [null, [Validators.required]]
					})
				});

				return this.projectForm.patchValue(oldValue);
			});
	}

	public async onSubmit(e: Event): Promise<void>{
		e.preventDefault();
		this.projectForm.markAllAsTouched();

		if (!this.projectForm.valid) {
			return;
		}

		this.loading = true;
		this.projectRepository.project$
			.pipe(
				take(1),
				switchMap((project) => this.projectRepository.updateProject(this.activatedRoute.snapshot.params.projectId, {
					...this.projectForm.value,
					accountId: project.accountId
				}).pipe(take(1)))
			)
			.subscribe(() => {
				this.loading = false;
				this.toastrService.success('Project geupdate');
				this.router.navigate(['/', 'projects'])
			});
	}

	public cancel(): void {
		if (this.activatedRoute.snapshot.queryParams.returnToEditor) {
			this.router.navigate(['/', 'projects']);
			return;
		}

		this.router.navigate(['/', 'projects']);
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
