import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, UntypedFormControl, NgControl, Validators} from '@angular/forms';

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	providers: [
		{
			provide: [],
			useExisting: forwardRef(() => TextInputComponent),
			multi: true,
		},
	],
})
export class TextInputComponent implements OnInit, OnDestroy, ControlValueAccessor {
	@Input() label?: string;
	@Input() placeholder = '';
	@Input() type = 'text';
	@Input() disabled = false;
	@Input() icon?: string;
	@Input() width?: string;
	@Input() inline = false;
	@Input() configuration = false;
	@Input() transparent = false;
	@Input() trapFocus = false;
	@Input() name?: string;
	@Input() hint?: string;

	@Output() blurInputField: EventEmitter<unknown> = new EventEmitter();
	@Output() keyEnter: EventEmitter<void> = new EventEmitter();

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public control: UntypedFormControl = new UntypedFormControl('', [Validators.required]);
	public updateValue = (_: any) => {};
	public onTouched = () => {};

	constructor(public ngControl: NgControl) {
		ngControl.valueAccessor = this;
	}

	private propagateChange(value: any): void {
		if (this.updateValue) {
			return this.updateValue(value);
		}

		if (this.control) {
			this.control.setValue(value, {
				emitEvent: false
			});
		}
	}

	public onBlurField(): void {
		this.onTouched();
		this.blurInputField.emit(this.control.value);
	}

	public ngOnInit(): void {
		if (this.disabled) {
			this.control.disable();
		}

		this.control.valueChanges.pipe(
			takeUntil(this.componentDestroyed$),
		).subscribe((value) => {
			this.propagateChange(value);
		});
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public writeValue(value: any): void {
		setTimeout(() => this.control.setValue(value, {
			emitEvent: false
		}));
	}

	public registerOnChange(fn: any): void {
		this.updateValue = fn;
	}

	public registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	public setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	public onKeyEnter(): void {
		if (this.control.valid) {
			this.keyEnter.emit();
		}
	}

}
