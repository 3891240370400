import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as Pages from './pages';

const routes: Routes = [
	{
		path: '',
		component: Pages.ProjectsComponent
	},
	{
		path: 'create',
		component: Pages.CreateComponent
	},
	{
		path: 'details/:projectId',
		component: Pages.DetailComponent
	},
	{
		path: ':projectId',
		component: Pages.EditorComponent,
		children: [
			{
				path: 'editor',
				component: Pages.CabinetBuilderComponent
			},
			{
				path: 'cutting-lists',
				loadChildren: () =>
					import('../cutting-lists/cutting-lists.module').then(
						(m) => m.CuttingListsModule
					),
			},
			{
				path: 'catalog',
				loadChildren: () =>
					import('../catalog/catalog.module').then(
						(m) => m.CatalogModule
					),
			},
			{
				path: 'hardware',
				loadChildren: () =>
					import('../hardware/hardware.module').then(
						(m) => m.HardwareModule
					),
			}
		]
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class ProjectsRoutingModule {}
