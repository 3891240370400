import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	FormControl,
	FormGroup,
	UntypedFormBuilder,
	Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { filter, Observable, Subject } from 'rxjs';
import { first, switchMap, take, takeUntil, tap } from 'rxjs/operators';

import { AccountService } from '~core/services/account.service';
import { AuthService } from '~core/services/auth.service';
import { ProjectService } from '~core/services/project.service';
import {
	BaseAccount,
	DeliverableAddressType
} from '~modules/auth/types/account.types';
import {
	ExternalUser,
	InternalUser,
	UserType
} from '~modules/auth/types/user.types';
import { CreateAccountModalComponent } from '~modules/projects/modals/create-account/create-account.modal';
import { CountryOptions } from '~shared/shared.const';
import { CustomValidators } from '~shared/validators/custom';

@Component({
	templateUrl: './create.component.html',
})
export class CreateComponent implements OnInit, OnDestroy {
	public loading = false;
	public deliveryAddressTypeFormControl =
		new FormControl<DeliverableAddressType>(DeliverableAddressType.SAME, [
			Validators.required,
		]);
	public projectForm: FormGroup;
	public countryOptions = CountryOptions;
	public accountOptions = [];
	public accountOptionsLoading$: Subject<boolean> = new Subject();
	public isAutoCreate = false;
	public user$: Observable<InternalUser | ExternalUser>;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly router: Router,
		private readonly projectService: ProjectService,
		private readonly authService: AuthService,
		private readonly accountService: AccountService,
		private readonly fb: UntypedFormBuilder,
		private readonly toastrService: ToastrService,
		private readonly activatedRoute: ActivatedRoute,
		private readonly dialogService: MatDialog
	) {}

	public ngOnInit(): void {
		this.user$ = this.authService.currentUser$;

		this.authService.currentUser$
			.pipe(
				tap((user) => {
					this.projectForm = this.fb.group({
						name: [null, Validators.required],
						accountId: [null, user?.userType === UserType.INTERNAL ? Validators.required : undefined],
					});

					if (this.activatedRoute.snapshot.queryParams.autoCreate === 'true' && (user?.userType === UserType.EXTERNAL)) {
						this.isAutoCreate = true;
						this.projectForm.patchValue({
							name: 'Nieuw project',
							accountId: (user as ExternalUser).accountId,
						});
						this.onSubmit();
					}
				}),
			)
			.subscribe()

		this.authService.currentUser$
			.pipe(
				filter((user) => !!user),
				takeUntil(this.componentDestroyed$),
				filter((user) => !user.account),
				tap(() => this.accountOptionsLoading$.next(true)),
				tap((user) => {
					this.projectForm = this.fb.group({
						name: [null, Validators.required],
						accountId: [null, Validators.required],
					});
				}),
				switchMap(() =>
					this.accountService.getAccounts().pipe(take(1))
				),
				tap(() => this.accountOptionsLoading$.next(false))
			)
			.subscribe((accounts: BaseAccount[]) => {
				this.accountOptions = accounts.map((account) => ({
					label: account.name,
					value: account.id,
				}));
			});

		this.deliveryAddressTypeFormControl.valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((deliveryAddressType) => {
				const name = this.projectForm.get('name').value;
				const account = this.projectForm.get('account')?.value;

				if (deliveryAddressType === DeliverableAddressType.SAME) {
					this.projectForm = this.fb.group({
						name: [null, Validators.required],
						accountId: [null],
					});

					return this.projectForm.patchValue({
						name,
						...(account && { account }),
					});
				}

				this.projectForm = this.fb.group({
					name: [null, Validators.required],
					accountId: [null],
					deliveryDetails: this.fb.group({
						firstname: [null, [Validators.required]],
						lastname: [null, [Validators.required]],
						telephonenumber: [
							null,
							[
								Validators.required,
								CustomValidators.validPhoneNumber,
							],
						],
						street: [null, [Validators.required]],
						busNumber: [null, []],
						houseNumber: [null, [Validators.required]],
						zip: [null, [Validators.required]],
						city: [null, [Validators.required]],
						country: [null, [Validators.required]],
					}),
				});

				return this.projectForm.patchValue({
					name,
					...(account && { account }),
				});
			});
	}

	public openCreateUserModal(): void {
		this.dialogService
			.open(CreateAccountModalComponent)
			.afterClosed()
			.pipe(first())
			.subscribe((createdAccountId: string) =>
				this.accountService
					.getAccounts()
					.pipe(take(1))
					.subscribe((accounts: BaseAccount[]) => {
						this.accountOptions = accounts.map((account) => ({
							label: account.name,
							value: account.id,
						}));
						this.projectForm.patchValue({ accountId: createdAccountId })
					})
			);
	}

	public async onSubmit(e?: Event): Promise<void> {
		e?.preventDefault();
		this.projectForm.markAllAsTouched();

		if (!this.projectForm.valid) {
			return;
		}

		const { value } = this.projectForm;
		this.loading = true;
		this.projectService
			.createProject({
				...value,
				accountId: value.accountId || this.authService.accountId,
			})
			.pipe(take(1))
			.subscribe((project) => {
				this.loading = false;
				this.toastrService.success('Project aangemaakt');
				this.router.navigate(['/', 'projects', project.id, 'editor']);
			});
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
