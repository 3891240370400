import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import Fuse from 'fuse.js'
import { omit, project } from 'ramda';

import { AuthService } from '~core/services/auth.service';
import { ProjectService } from '~core/services/project.service';
import { Button, StandardDialogComponent } from '~shared/components/standard-dialog/standard-dialog.component';
import { ProjectsRepository } from '~modules/projects/store/projects/projects.repository';
import { PartsRepository } from '~modules/projects/store/parts/parts.repository';
import { Project, Part } from '~shared/types';
import { ProjectStatus } from '~shared/enums';
import { UserType } from '~modules/auth/types/user.types';
import { StatusModalComponent } from '~modules/projects/modals/status/status.modal';
import { StatusHistoryModalComponent } from '~modules/projects/modals/status-history/status-history.modal';
import { DeliveryModalComponent } from '~modules/projects/modals/delivery/delivery.modal';

import { PartStatuses } from '../../projects.const';

@Component({
	templateUrl: './projects.component.html',
})
export class ProjectsComponent implements OnInit, OnDestroy {
	public initialLoading = true;
	public projects$: Observable<Project[]>;
	public projectsLoading$: Observable<boolean>;
	public parts$: Observable<Part[]>;
	public partsLoading$: Observable<boolean>;
	public openProject: string;
	public partStatuses = PartStatuses;
	public partStatusOptions = Object.keys(PartStatuses).map((key) => ({ label: PartStatuses[key].label, value: key }));
	public searchForm = new FormControl('')

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly projectService: ProjectService,
		private readonly dialog: MatDialog,
		private readonly authService: AuthService,
		private readonly projectsRepository: ProjectsRepository,
		private readonly partsRepository: PartsRepository,
	) {}

	// Gets all projects
	ngOnInit(): void {
		this.projects$ = combineLatest([
			this.searchForm.valueChanges.pipe(startWith(null)),
			this.projectsRepository.projects$.pipe(startWith(null)),
		])
			.pipe(map(([search, projects]) => {
				if (!search) {
					return projects
				}

				const fuse = new Fuse(projects, {
					keys: ['name', 'accountName'],
					includeScore: true,
					shouldSort: false,
					threshold: 0.2
				})

				return fuse.search(search).map((i) => i.item);
			}));

		if (this.authService.automaticOverwrite) {
			this.authService.overwriteAccountId(null, false);
		}

		this.projectsLoading$ = this.projectsRepository.projectsLoading$;
		this.parts$ = this.partsRepository.parts$
			.pipe(
				map((parts) => parts.map((part) => {
					const statusControl = new FormControl(part.status);
					const expectedDeliveryDateControl = new FormControl(part.expectedDeliveryDate);

					statusControl.valueChanges
						.pipe(
							takeUntil(this.componentDestroyed$),
							switchMap((status) => this.partsRepository.updateStatus(part.id, status)),
							take(1)
						)
						.subscribe();

					return {
						...part,
						statusControl,
						expectedDeliveryDateControl,
					}
				}))
			);
		this.partsLoading$ = this.partsRepository.partsLoading$;

		this.fetchData();
	}

	public fetchData(): void {
		this.authService.currentUser$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((user) => !!(user && user.account?.id) || (user?.userType === UserType.INTERNAL && user.registered)),
				tap((user) => {
					this.initialLoading = false;
					this.projectsRepository.getProjects(user.account?.id)
				})
			)
			.subscribe();
	}

	public toggleProject(projectId: string, open: boolean): void {
		if (!open) {
			return this.openProject = null;
		}

		this.openProject = projectId;
		this.partsRepository.clearParts();
		this.partsRepository.getParts({ projectId })
			.pipe(take(1))
			.subscribe();
	}

	async orderProject(project: Project): Promise<void> {
		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Bestelling Project',
				body: `Bent u zeker dat u project "${project.name}" wil bestellen?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'ORDER', 'primary')],
				icon: 'cart',
				type: 'warning'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'ORDER') {
					return;
				}

				this.projectService.updateProjectStatus(project.id, ProjectStatus.IN_PROGRESS)
					.pipe(take(1))
					.subscribe(() => this.fetchData());
			})
	}

	async deleteProject(project: Project): Promise<void> {
		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Verwijderen Project',
				body: `Bent u zeker dat u project "${project.name}" wil verwijderen?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DELETE', 'primary')],
				icon: 'trash',
				type: 'danger'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DELETE') {
					return;
				}

				const lastVisitedProject = localStorage.getItem('lastVisitedProject');
				if (lastVisitedProject === project.id) {
					localStorage.removeItem('lastVisitedProject');
				}
				this.projectService.deleteProject(project.id)
					.pipe(take(1))
					.subscribe(() => this.fetchData());
			})
	}

	async duplicateProject(project: Project): Promise<void> {
		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Dupliceren Project',
				body: `Bent u zeker dat u project "${project.name}" wil dupliceren?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DUPLICATE', 'primary')],
				icon: 'duplicate',
				type: 'warning'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DUPLICATE') {
					return;
				}

				this.projectService.duplicateProject(project.id)
					.pipe(take(1))
					.subscribe(() => this.fetchData());
			})
	}

	async deletePart(part: Part, project: Project): Promise<void> {
		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Verwijderen kast',
				body: `Bent u zeker dat u kast "${part.name}" wil verwijderen uit project "${project.name}"?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DELETE', 'primary')],
				icon: 'trash',
				type: 'danger'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DELETE') {
					return
				}

				this.projectService.deletePart(part.id)
					.pipe(take(1))
					.subscribe(() => {
						this.partsRepository.getParts({ projectId: project.id })
							.pipe(take(1))
							.subscribe();
					});
			})
	}

	async handleStatusClick(project: Project, part: Part): Promise<void> {
		const dialogRef = this.dialog.open(StatusHistoryModalComponent, {
			data: { partId: part.id, projectId: project.id }
		});

		dialogRef
			.afterClosed()
			.pipe(take(1))
			.subscribe()
	}

	public onBlurInputField(part: Part, time: string): void {
		this.partsRepository.updatePart(part.id, {
			...omit(['statusControl', 'expectedDeliveryDateControl', 'project'])(part),
			expectedDeliveryDate: time === 'EMPTY' ? null : time,
			projectId: part.project.id
		})
			.pipe(take(1))
			.subscribe()
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
