import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	Output,
	ViewChild,
} from '@angular/core';
import { map, Observable, Subject, switchMap, take, takeUntil, tap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

import { ArticleType, PanelType } from '~shared/enums/item.enum';
import { Item } from '~shared/types';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { SelectionType } from '~shared/shared.types';
import {
	EngineRenderService,
	GenericItem,
	ShapeInfo,
} from '~shared/components/cabinet-builder/engine-render.service';
import { EditorMode } from '~modules/projects/store/editor/editor.types';

import { SelectionManager } from './selectionManager';

declare var ResizeObserver;

@Component({
	selector: 'app-cabinet-builder',
	templateUrl: './cabinet-builder.component.html',
	providers: [EngineRenderService],
})
export class CabinetBuilderComponent implements AfterViewInit, OnChanges, OnDestroy {
	@Input() item: Item;
	@Input() disableInteraction: boolean = false;
	@Input() hideDoors: boolean = false;
	@Input() transparentPanelTypes: PanelType[];
	@Input() isolatedItem: GenericItem;
	@Input() editorMode: EditorMode = EditorMode.DEFAULT;
	@Input() renderView: string = 'default';

	@ViewChild('container', { read: ElementRef }) containerRef: ElementRef;
	@Output() selectShape: EventEmitter<Array<ShapeInfo>> = new EventEmitter();

	public observer: ResizeObserver;

	private componentDestroyed$: Subject<boolean> = new Subject();

	doorObserver;

	constructor(
		private zone: NgZone,
		private engineService: EngineRenderService,
		private editorRepository: EditorRepository,
		private route: ActivatedRoute,
	) {}

	ngOnChanges() {
		//// Call this once there has been a change to the item and it needs to be rebuild
		this.rebuildItem();
	}

	ngOnDestroy() {
		if (this.engineService.engine.renderer != null) {
			this.engineService.engine.dispose();
		}

		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	ngAfterViewInit(): void {
		// We have to run this outside angular zones,
		// because it could trigger heavy changeDetection cycles.
		this.observer = new ResizeObserver((entries) => {
			this.engineService.engine.width = entries[0].contentRect.width;
			this.engineService.engine.height = entries[0].contentRect.height;
		});
		this.observer.observe(this.containerRef.nativeElement);
		this.zone.runOutsideAngular(() => this.initialise());

		const object = this.route.snapshot.queryParams.object;

		if (object) {
			const mesh = this.engineService.itemEditor.findIdInScene(object);

			if (mesh?.userData?.object) {
				this.editorRepository.setSelectedItems([mesh?.userData?.object]);
				this.engineService.itemEditor.lastSelectedObj = mesh;
				this.handleConfigurationPanel(mesh?.userData?.object);
			}
		}

		this.editorRepository.selectedItems$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((items) => {
				this.engineService.itemEditor.highlightObjects(items, this.isolatedItem);
			});

		this.editorRepository.openDoorIds$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((doorIds) => {
				this.engineService.itemEditor.triggerDoors(true, doorIds)
			})
	}

	initialise() {
		this.engineService.init(this.containerRef.nativeElement);
		this.engineService.initItemEditor();
		this.buildItem();
	}

	buildItem() {
		// Initial build
		this.editorRepository.openDoorIds$
			.pipe(take(1))
			.subscribe((openDoorIds) => {
				this.engineService.itemEditor.build({
					renderView: this.renderView,
					item: this.item,
					transparentPanelTypes: this.transparentPanelTypes,
					isolatedItem: this.isolatedItem,
					openDoorIds,
					hideDoors: this.hideDoors,
					editorMode: this.editorMode
				});
			})

		this.engineService.animateDuplicate();
	}

	public handleConfigurationPanel(object?: GenericItem): void {
		if (this.editorMode !== EditorMode.DEFAULT) {
			return;
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.DOOR
		) {
			this.editorRepository.setFillerHighlight(null);
			return this.editorRepository.setActiveConfigurationPanel('DOOR');
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.ARTICLE_ZONE
		) {
			this.editorRepository.setFillerHighlight(null);
			return this.editorRepository.setActiveConfigurationPanel('LAYOUT');
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.FACE
		) {
			this.editorRepository.setFillerHighlight(null);
			return this.editorRepository.setActiveConfigurationPanel('FACE');
		}

		if (
			SelectionManager.getSelectionType(object) ===
			SelectionType.ARTICLE
		) {
			if (object.article.articleType === ArticleType.DRAWER) {
				return this.editorRepository.setActiveConfigurationPanel(
					`DRAWER`
				);
			}

			if (object.article.articleType === ArticleType.SIDEMOUNTED_ROD) {
				return this.editorRepository.setActiveConfigurationPanel(
					`SIDEMOUNTED_ROD`
				);
			}

			if (object.article.articleType === ArticleType.SUSPENDED_ROD) {
				return this.editorRepository.setActiveConfigurationPanel(
					`SUSPENDED_ROD`
				);
			}

			if (object.panelType === PanelType.BASE_BACK) {
				return this.editorRepository.setActiveConfigurationPanel(
					`BASE`
				);
			}

			if (object.panelType === PanelType.BASE_FRONT) {
				return this.editorRepository.setActiveConfigurationPanel(
					`BASE`
				);
			}

			if (object.panelType === PanelType.DIVIDER) {
				// TODO: actually get the orientation from the backend, it currently is not passed.
				return this.editorRepository.setActiveConfigurationPanel(
					`XZ_DIVIDER`
				);
			}

			if (object.panelType === PanelType.BACK) {
				return this.editorRepository.setActiveConfigurationPanel(
					'BACK'
				);
			}

			if ([PanelType.FILLER_FRONT, PanelType.FILLER_RECESSED, PanelType.FILLER_PANEL].includes(object.panelType)) {
				this.editorRepository.setActiveConfigurationPanel(
					`FILLER_CONNECTION`
				);
				return this.editorRepository.setSelectedFaceIdx(object.outlineFaceIdx);
			} else {
				this.editorRepository.setSelectedFaceIdx(null);
			}

			if (object.panelType === PanelType.FILLER_SUPPORT) {
				this.editorRepository.setActiveConfigurationPanel(
					`FILLER_SUPPORT`
				);
				return this.editorRepository.setSelectedFaceIdx(object.outlineFaceIdx);
			} else {
				this.editorRepository.setSelectedFaceIdx(null);
			}

			if (object.panelGroup === ArticleType.CARCASE) {
				return this.editorRepository.setActiveConfigurationPanel(
					'CARCASE'
				);
			}

			if (object.panelType === PanelType.SHELF) {
				return this.editorRepository.setActiveConfigurationPanel(
					'SHELVES'
				);
			}

			if (object.panelType === PanelType.FRAME) {
				return this.editorRepository.setActiveConfigurationPanel(
					'FRAME'
				);
			}

			this.editorRepository.setActiveConfigurationPanel(null);
		} else {
			this.editorRepository.setActiveConfigurationPanel(null);
			this.editorRepository.setSelectedFaceIdx(null);
		}
	}

	select3dObject(e: PointerEvent) {
		if (!this.engineService.engine.renderer || this.disableInteraction) {
			return;
		}

		// ctrlKey = ctrl on windows
		// metaKey = command on mac
		const result = this.engineService.itemEditor.selectObject(
			e.offsetX,
			e.offsetY,
			e.ctrlKey || e.metaKey
		);

		if (!result) {
			return;
		}

		const { primaryObjects } = result;
		const firstShapeInfo = primaryObjects?.[0];

		this.editorRepository.setSelectedItems(
			(primaryObjects || []).map((o) => o?.object)
		);

		this.handleConfigurationPanel(firstShapeInfo?.object);
	}

	isolate3dObject(e: PointerEvent) {
		if (!this.engineService.engine.renderer || this.disableInteraction) {
			return;
		}

		this.editorRepository.setOpenDoorsIds([]);
		this.editorRepository.setMode(EditorMode.DEFAULT);

		// ctrlKey = ctrl on windows
		// metaKey = command on mac
		const result = this.engineService.itemEditor.selectObject(
			e.offsetX,
			e.offsetY,
			e.ctrlKey || e.metaKey
		);

		this.editorRepository.isolatedItem$
			.pipe(take(1))
			.subscribe((item) => {
				if (item) {
					this.editorRepository.setSelectedItems([]);
					this.editorRepository.setActiveConfigurationPanel(null);
					return this.editorRepository.setIsolatedItem(null);
				}

				this.editorRepository.setSelectedItems([result.primaryObjects[0]?.object]);
				this.editorRepository.setIsolatedItem(result.primaryObjects[0]?.object);
			})
	}

	onRightClick(event: Event): void {
		// this will disable default action of the context menu
		event.preventDefault();
	}

	startTimer() {
		this.engineService.itemEditor.startTimer();
	}

	endTimer() {
		this.engineService.itemEditor.endTimer();
	}

	rebuildItem() {
		if (
			this.engineService.engine &&
			this.engineService.engine.renderer != null
		) {
			this.engineService.itemEditor.disposeCurrentModel();
			this.buildItem();
		}
	}
}
