import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WrapperComponent } from '~core/pages/wrapper/wrapper.component';

import { AuthGuard } from './modules/auth/guards/auth.guard';

const routes: Routes = [
	// {
	//   path: 'auth',
	//   loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
	// },
	{
		path: 'preview',
		loadChildren: () =>
			import('./modules/preview/preview.module').then((m) => m.PreviewModule),
		data: {
			disableNavigation: true,
		}
	},
	{
		path: '',
		component: WrapperComponent,
		children: [
			{
				path: '',
				redirectTo: 'projects',
				pathMatch: 'full'
			},
			{
				path: 'core',
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				loadChildren: () =>
					import('./core/core.module').then((m) => m.CoreModule),
			},
			{
				path: 'projects',
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				loadChildren: () =>
					import('./modules/projects/projects.module').then(
						(m) => m.ProjectsModule
					),
			},
			{
				path: 'users',
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				loadChildren: () =>
					import('./modules/users/users.module').then((m) => m.UsersModule),
			},
			{
				path: 'auth',
				loadChildren: () =>
					import('./modules/auth/auth.module').then((m) => m.AuthModule),
			},
			{   path: 'algemene_voorwaarden',
				loadChildren: () =>
					import('./modules/public/public.module').then((m) => m.PublicModule),
			},
			{
				path: 'materials',
				canActivate: [AuthGuard],
				canActivateChild: [AuthGuard],
				loadChildren: () =>
					import('./modules/boards/boards.module').then((m) => m.BoardsModule),
			},
		]
	},
	{
		path: '**',
		redirectTo: 'projects'
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
