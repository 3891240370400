import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	AfterViewInit,
	SimpleChanges,
	Output,
	ViewChild, NgZone
} from '@angular/core';
import {
	BehaviorSubject,
	combineLatest,
	debounceTime,
	map,
	Observable,
	of,
	pairwise,
	Subject,
	switchMap,
	take,
	takeUntil,
	tap,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { equals } from 'ramda';

import {
	Part,
	IArticle,
	IArticleZone,
	Item,
} from '~shared/types';
import { PanelType, ThreeObjectTypeEnum } from '~shared/enums';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import {
	EngineRenderService,
	GenericItem,
	ShapeInfo,
} from '~shared/components/cabinet-builder/engine-render.service';
import { PartEditorRepository } from '~modules/projects/store/part-editor/part-editor.repository';
import { Button, StandardDialogComponent } from '~shared/components/standard-dialog/standard-dialog.component';

@Component({
	selector: 'app-part-editor',
	templateUrl: './part-editor.component.html',
	providers: [EngineRenderService],
})
export class PartEditorCanvasComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() part: Part;

	@Output() public toggleFullscreen = new EventEmitter();

	@ViewChild('container', { read: ElementRef }) containerRef: ElementRef;

	public buttons$: Observable<
		{ type: string; icon?: string; action?: string }[]
	>;
	public showDivider = false;
	public selectedId: string;
	public shapeInfo: Array<ShapeInfo>;
	public showDoor = false;
	public doorsTransparent$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public selectedArticleZones$: Observable<IArticleZone[]>;
	public selectedArticles$: Observable<IArticle[]>;
	public selectedDoors$: Observable<GenericItem[]>;
	public activeItem$: Observable<Item>;
	public fullscreen$: Observable<boolean>;
	public lastView$ = new BehaviorSubject('z');
	public loading = true;
	public history = []
	public partHasNoItems = false;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly dialog: MatDialog,
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
		private readonly partEditorRepository: PartEditorRepository,
		private zone: NgZone,
		private engineService: EngineRenderService
	) {}

	public ngOnInit(): void {
		this.fullscreen$ = this.editorRepository.partEditorFullscreen$
			.pipe(
				tap(() => {
					setTimeout(() => this.engineService?.engine?.resize())
				})
			);
		this.partEditorRepository.clearHistory();

		this.itemsRepository.items$
			.pipe(
				tap((items) => {
					this.loading = false;
					this.partHasNoItems = !items.length
			}), takeUntil(this.componentDestroyed$)).subscribe();

		this.buttons$ = combineLatest([
			this.editorRepository.selectedItems$,
			this.lastView$,
			this.partEditorRepository.hasFuture$,
			this.partEditorRepository.hasPast$,
		]).pipe(
			takeUntil(this.componentDestroyed$),
			//@ts-ignore
			switchMap(([items, lastView, hasFuture, hasPast]) => {
				return of([
					{
						type: 'button',
						icon: 'top-view',
						action: 'y',
						active: lastView === 'y',
					},
					{
						type: 'button',
						icon: 'front-view',
						action: 'z',
						active: lastView === 'z',
					},
					{
						type: 'button',
						icon: 'side-view',
						action: 'x',
						active: lastView === 'x',
					},
					{
						type: 'divider',
					},
					{
						type: 'button',
						icon: 'rotate-anti-clockwise',
						action: 'ROTATE_ANTI_CLOCKWISE',
						disabled: !items.length,
					},
					{
						type: 'button',
						icon: 'rotate-clockwise',
						action: 'ROTATE_CLOCKWISE',
						disabled: !items.length,
					},
					{
						type: 'divider',
					},
					{
						type: 'button',
						icon: 'undo',
						action: 'UNDO',
						disabled: !hasPast,
					},
					{
						type: 'button',
						icon: 'redo',
						action: 'REDO',
						disabled: !hasFuture,
					},
					{
						type: 'divider',
					},
					{
						type: 'button',
						icon: 'delete',
						action: 'DELETE',
						disabled: !items.length,
					},
				]);
			})
		);
	}

	initialiseEngine(items: Item[]) {
		this.engineService.init(this.containerRef.nativeElement);
		this.engineService.initPartEditor();
		this.buildItem(items);
	}

	buildItem(items: Item[]) {
		items.forEach((item: Item) => {
			this.engineService.partEditor.build(item, 100);
		});
		this.engineService.partEditor.arrangeItems(true, true);
		this.engineService.animateDuplicate();
	}

	public toggleDoorTransparency(shouldOpen: boolean): void {
		combineLatest([of(shouldOpen), this.itemsRepository.items$])
			.pipe(
				take(1),
				tap(([isOpen, items]: [boolean, Item[]]) => {
					const doorIds = items.reduce((acc, item) => ([...acc, ...this.getDoorIds(item)]), []);
					this.engineService.partEditor.triggerDoors(true, isOpen ? doorIds : []);
					this.doorsTransparent$.next(shouldOpen);
				})
			)
			.subscribe()
	}

	private getDoorIds(item: Item): string[] {
		return item?.articles?.reduce((articleAcc, article) => {
			return [...articleAcc, ...(article?.panels || [])?.reduce((panelAcc, panel) => {
				if (![PanelType.FRONT, PanelType.DRAWER_FRONT].includes(panel.panelType)) {
					return panelAcc;
				}

				return [panel.id, ...panelAcc];
			}, [])]
		}, [])
	}

	public ngOnChanges(changes: SimpleChanges): void {

	}

	onButtonClick(action: string): void {
		switch (action) {
		case 'x':
			this.engineService.partEditor.lockAxis(action);
			this.lastView$.next('x');
			break;
		case 'y':
			this.engineService.partEditor.lockAxis(action);
			this.lastView$.next('y');
			break;
		case 'z':
			this.engineService.partEditor.lockAxis(action);
			this.lastView$.next('z');
			break;
		case 'ROTATE_ANTI_CLOCKWISE':
			this.engineService.partEditor.rotateObject(1.5708);
			break;
		case 'DELETE':
			this.onDeleteSelectedItem();
			break;
		case 'ROTATE_CLOCKWISE':
			this.engineService.partEditor.rotateObject(-1.5708);
			break;
		case 'REDO':
			this.partEditorRepository.redo();
			this.partEditorRepository.itemPositions$
				.pipe(
					take(1),
					switchMap((positions) => combineLatest(positions.map((position) => this.itemsRepository
						.updateItemCoordinates(position.itemId, position.partId, {
							partCoordinate: position.position,
							partRotation: position.rotation,
						})))),
					take(1)
				).subscribe();
			break;
		case 'UNDO':
			this.partEditorRepository.undo();
			this.partEditorRepository.itemPositions$
				.pipe(
					take(1),
					switchMap((positions) => combineLatest(positions.map((position) => this.itemsRepository
						.updateItemCoordinates(position.itemId, position.partId, {
							partCoordinate: position.position,
							partRotation: position.rotation,
						})))),
					take(1)
				).subscribe();
			break;
		default:
			break;
		}
	}

	ngAfterViewInit() {
		this.itemsRepository.items$
			.pipe(
				tap((items) => {
					this.zone.runOutsideAngular(() => this.initialiseEngine(items));
				}),
				switchMap(() => this.engineService.partEditor.positions$),
				debounceTime(500),
				pairwise(),
				switchMap(([oldPositions, newPositions]) => {
					this.partEditorRepository.setItemPositions(newPositions);

					const newItems = newPositions.map(item => item.itemId);
					oldPositions = oldPositions.filter(item => newItems.includes(item.itemId));

					return combineLatest(newPositions
						.filter((position, i) => !equals(position?.position, oldPositions?.[i]?.position) || !equals(position?.rotation, oldPositions?.[i]?.rotation))
						.map((position) => this.itemsRepository
							.updateItemCoordinates(position.itemId, position.partId, {
								partCoordinate: position.position,
								partRotation: position.rotation,
							})));
				}),
				takeUntil(this.componentDestroyed$)
			)
			.subscribe();

		this.partEditorRepository.itemPositions$
			.pipe(
				takeUntil(this.componentDestroyed$),
			)
			.subscribe((positions) => {
				if (!positions.length || !this.engineService.partEditor) {
					return;
				}

				this.engineService.partEditor.updateItemPositions(positions)
			});
	}

	onFullscreenClick(): void {
		this.editorRepository.partEditorFullscreen$
			.pipe(take(1))
			.subscribe((fullscreen) =>
				this.editorRepository.setPartEditorFullscreen(!fullscreen)
			);
	}

	onDeleteSelectedItem(): void {
		this.editorRepository.partEditorSelectedItem$
			.pipe(
				take(1),
				switchMap((itemId) => {
					return this.itemsRepository.items$
						.pipe(take(1), map((items) => items.find((item) => item.id === itemId)))
				})
			)
			.subscribe((item) => {
				const dialogRef = this.dialog.open(StandardDialogComponent, {
					data: {
						title: 'Item verwijderen',
						body: `Bent u zeker dat u dit item "${item.name}" wilt verwijderen?`,
						buttons: [new Button('Annuleren'), new Button('OK', 'CONFIRM', 'primary')],
						icon: 'status',
						type: 'warning'
					},
				});

				dialogRef
					.afterClosed()
					.subscribe((action) => {
						if (action !== 'CONFIRM') {
							return;
						}

						this.itemsRepository.deleteItem(item.partId, item.id)
							.pipe(take(1), switchMap(() =>
								this.itemsRepository
									.getItems([this.part.id])
									.pipe(take(1)))
							)
							.subscribe();
					})
			})
	}

	onShapeSelect(shapeInfo: Array<ShapeInfo>) {
		this.showDoor = false;
		this.showDivider = false;

		if (shapeInfo?.length === 1) {
			if (shapeInfo[0].type === ThreeObjectTypeEnum.LAYOUT) {
				this.showDivider = true;
				this.shapeInfo = shapeInfo;
			}
		}

		if (shapeInfo?.length >= 1) {
			if (
				shapeInfo.every(
					(shapeInfo) => shapeInfo.type === ThreeObjectTypeEnum.LAYOUT
				)
			) {
				this.showDoor = true;
				this.shapeInfo = shapeInfo;
			}
		}
	}

	public ngOnDestroy(): void {
		this.editorRepository.setPartEditorSelectedItem(null);
		// this.engineService.collisionManager.removeEvents();
		this.engineService?.engine?.dispose();
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
