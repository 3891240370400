import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '~core/services/auth.service';
import { ExternalUser, InternalUser } from '~modules/auth/types/user.types';

import { HeaderMenuItem } from './header.types';
import {Router} from "@angular/router";

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	@Input() menuItems: HeaderMenuItem[] = [];
	@Output() sidenavToggle = new EventEmitter<void>();

	public currentUser$: Observable<InternalUser | ExternalUser>
	public isTermsPage = false;


	constructor(
		private readonly authService: AuthService,
		private readonly router: Router,
	) {}

	public ngOnInit(): void {
		this.isTermsPage = this.router.url.includes('algemene_voorwaarden');

		this.currentUser$ = this.authService.currentUser$;
	}
}
