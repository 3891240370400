import { Injectable, NgZone, OnDestroy } from '@angular/core';

import { IArticle, IArticleZone, ICutout, IParameterSet } from '~shared/types';
import {
	ArticleType,
	Colour,
	FrontConnection,
	PanelType,
	ThreeObjectTypeEnum,
} from '~shared/enums';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { PartsRepository } from '~modules/projects/store/parts/parts.repository';
import { IConnection } from '~shared/types/connection.types';

import { Engine } from './engine';
import { TweenManager } from './tweenManager';
import { Measurements } from './measurements';
import { SelectionManager } from './selectionManager';
import { ObjectBuilder } from './objectBuilder';
import { PartEditor } from './partEditor';
import { ItemEditor } from './itemEditor';

@Injectable()
export class EngineRenderService implements OnDestroy {
	engine: Engine;
	objectBuilder: ObjectBuilder;
	tweenManager: TweenManager;
	measurementsManager: Measurements;
	selectionManager: SelectionManager;
	itemEditor: ItemEditor;
	partEditor: PartEditor;

	constructor(
		private ngZone: NgZone,
		private editorRepository: EditorRepository,
		private itemsRepository: ItemsRepository,
		private partsRepository: PartsRepository
	) {}

	ngOnDestroy(): void {
		this.measurementsManager?.dispose();
		this.engine?.dispose();

		this.itemEditor?.removeEvents();
		this.partEditor?.removeEvents();
	}

	init(container: HTMLElement): Promise<void> {
		//// Scene set up
		this.engine = new Engine(container);

		//// Init managers
		this.tweenManager = new TweenManager(this.engine);
		this.measurementsManager = new Measurements(this.engine.scene);
		this.selectionManager = new SelectionManager(
			this.engine.raycaster,
			this.engine.scene,
			this.engine.camera
		);
		this.objectBuilder = new ObjectBuilder(this.engine);

		return this.engine.initScene();
	}

	animateDuplicate(): void {
		// We have to run this outside angular zones,
		// because it could trigger heavy changeDetection cycles.
		this.ngZone.runOutsideAngular(() => {
			if (document.readyState !== 'loading') {
				this.engine.needToRender(100);
			} else {
				window.addEventListener('DOMContentLoaded', () => {
					this.engine.needToRender(100);
				});
			}

			window.addEventListener('resize', () => {
				this.engine.resize();
			});
		});
	}

	//// Item editor
	initItemEditor() {
		this.itemEditor = new ItemEditor(
			this.objectBuilder,
			this.engine,
			this.measurementsManager,
			this.selectionManager,
			this.editorRepository
		);
		//// For measurement text to always face the camera
		this.engine.measurementsManager = this.measurementsManager;
		//// For cutouts movement
		this.itemEditor.addMovementEvents();
	}

	//// Part editor
	initPartEditor(disableInteraction = false) {
		this.partEditor = new PartEditor(
			this.objectBuilder,
			this.engine,
			this.selectionManager,
			this.tweenManager,
			this.editorRepository,
			this.itemsRepository,
			this.partsRepository,
		);

		if (!disableInteraction) {
			this.partEditor.addMovementEvents();
		}
	}
}

export interface GenericItem {
	panelType?: PanelType;
	backPanelType?: string;
	edgeband?: boolean;
	faceNormal?: string;
	faceIndex?: number;
	disMountable?: boolean;
	panelGroup?: ArticleType;
	id: string;
	connectorType?: string;
	colour?: Colour;
	articleZone?: IArticleZone;
	article?: IArticle;
	outlineFaceIdx?: number;
	frontConnection?: FrontConnection;
	cutouts?: ICutout[];
	construction?: {
		topConstructionType?: string;
		topConstructionTypeOptions?: string[];
		bottomConstructionType?: string;
		bottomConstructionTypeOptions?: string[];
		leftConstructionType?: string;
		leftConstructionTypeOptions?: string[];
		rightConstructionType?: string;
		rightConstructionTypeOptions?: string[];
	}
	hingeConnection?: IConnection;
	handleConnection?: any;
	removable?: boolean;
	articleZoneBackFace?: boolean;
	faceIdx?: number;
	customisations?: string[];
	parameterSet?: IParameterSet;
	board?: {
		sku: string;
	};
	type?: string;
	location?: string;
	shape?: Partial<ShapeParameters>
}

export class ShapeParameters {
	width: number;
	height?: number;
	depth?: number;
	length?: number;
	thickness?: number;
}

export class ShapeInfo {
	type: ThreeObjectTypeEnum;
	object: GenericItem;
}
