import {Component,  Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {Subject, take, takeUntil} from 'rxjs';

import { DesignerMode } from '~shared/enums';
import { Item, Part } from '~shared/types';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { aggregateDimensions } from '~modules/projects/helpers/aggregateDimensions';
import { FillerControllerComponent } from '~modules/projects/ribbon-components/filler-controller/filler-controller.component';

import { AddCabinetButtonComponent } from '../../ribbon-components/button-controller/button-controller.component';
import { InfoControllerComponent } from '../../ribbon-components/info-controller/info-controller.component';
import { OutlineControllerComponent } from '../../ribbon-components/outline-controller/outline-controller.component';
import { ControllerItem } from '../item-details-ribbon/item-details-ribbon.component';
import {EditorState} from "~modules/projects/store/editor/editor.types";

@Component({
	selector: 'app-editor-wrapper',
	templateUrl: './editor-wrapper.component.html'
})
export class EditorWrapperComponent implements OnChanges, OnInit, OnDestroy {
	// Configurator component groups all item data together to be used by child components
	@Input() public item: Item;
	@Input() public mode: DesignerMode;
	@Input() public part: Part;

	public ribbonControllers: ControllerItem[] = [];
	public designerPanelForm: UntypedFormGroup;
	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private itemRepository: ItemsRepository,
		private editorRepository: EditorRepository
	) {}

	ngOnInit(): void {
		this.editorRepository.triggerCabinetCreation$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((createStatus) => {
				if (this.item && createStatus) {
					this.createNewCabinet();
					this.editorRepository.triggerCabinetCreation$.next(false);
				}
			});
	}


	createNewCabinet(): void {
		this.editorRepository.allConfig$
			.pipe(take(1))
			.subscribe((state: EditorState) => {
				if (this.areDimensionsValid(state.dimensions) && (this.item.id === 'new')) {
					this.itemRepository.saveTempNewItem(aggregateDimensions(state))
				}
			});
	}

	areDimensionsValid(dimensions: Record<string, number | string>) {
		return Object.values(dimensions).every(value => !!value && !isNaN(Number(value)));
	}

	public ngOnChanges(changes: SimpleChanges): void {
		if (this.item) {
			this.addControlsToRibbon();
		}
	}

	ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public addControlsToRibbon () {
		if (this.item.id !== 'new') {
			return this.ribbonControllers = [
				new ControllerItem(InfoControllerComponent),
				new ControllerItem(OutlineControllerComponent),
				new ControllerItem(FillerControllerComponent)
			];
		}

		return this.ribbonControllers = [
			new ControllerItem(InfoControllerComponent),
			new ControllerItem(OutlineControllerComponent),
			new ControllerItem(AddCabinetButtonComponent, { icon: 'build.svg', tooltip: 'Bouw kast' })
		];
	}
}
