import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { DesignerMode } from '~shared/enums';
import {Part, Project, Item, ProjectTree} from '~shared/types';

@Component({
	selector: 'app-navigation-panel',
	templateUrl: './navigation-panel.component.html'
})

export class NavigationPanelComponent implements OnInit {
	@Input() project: ProjectTree;
	@Input() items: Item[];
	@Input() parts: Part[];
	@Input() openPart: string;
	@Input() showNewCabinetSelection: Record<string, boolean>;
	@Input() selectedItem: Item;
	@Input() selectedPart: Part;

	@Output() selectItem = new EventEmitter();
	@Output() createItem = new EventEmitter();
	@Output() createPart = new EventEmitter();
	@Output() togglePart = new EventEmitter();
	@Output() setNewCabinetSelection = new EventEmitter();
	@Output() selectPart = new EventEmitter();
	@Output() returnToMainView = new EventEmitter();

	public designerModes = DesignerMode;
	public designerMode$: Observable<DesignerMode>;

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly router: Router
	) {}

	public ngOnInit(): void {
		this.designerMode$ = this.editorRepository.designerMode$;
	}

	public onSelectItem({ item, part }: { item: Item, part: Part }): void {
		this.selectItem.emit({ item, part });
	}

	public onSelectPart(data: Part): void {
		this.selectPart.emit(data);
	}

	public onTogglePart(partId: string): void {
		this.togglePart.emit(partId);
	}

	public onCreateItem(part: Part): void {
		this.createItem.emit(part);
	}

	public onSetNewCabinetSelection(partId: string): void {
		this.setNewCabinetSelection.emit(partId);
	}

	public onSetDesignerMode(designerMode: DesignerMode): void {
		this.editorRepository.setDesignerMode(designerMode);

		switch (designerMode) {
		case DesignerMode.CUTTING_LIST:
			this.router.navigate(['/', 'projects', this.project.id, 'cutting-lists']);
			break;

		default:
			this.router.navigate(['/', 'projects', this.project.id, 'editor']);
		}
	}

	public onCreatePart(): void {
		this.createPart.emit(null);
	}

}
