import { Component, EventEmitter, Input, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router} from '@angular/router';
import { take } from 'rxjs';

import { ProjectStatuses } from '~modules/projects/projects.const';
import { ProjectsRepository } from '~modules/projects/store/projects/projects.repository';
import { Button, StandardDialogComponent } from '~shared/components/standard-dialog/standard-dialog.component';
import { ProjectStatus } from '~shared/enums';
import {Project, ProjectTree} from '~shared/types';
import {FormControl} from "@angular/forms";

@Component({
	selector: 'app-project-header',
	templateUrl: './project-header.component.html'
})

export class ProjectHeaderComponent {
	@Input() project: ProjectTree;
	@Input() selectedIndex: number; // Selected index (Yellow square)

	@Output() returnToMainView = new EventEmitter();
	public editingNameControl = new FormControl('');

	public projectStatuses = ProjectStatuses;
    public editorStatus = false;
	constructor(
		private readonly dialog: MatDialog,
		private readonly projectsRepository: ProjectsRepository,
		private readonly router: Router,
	) {

	}

	showProjectInfo(){
		this.returnToMainView.emit();
	}

	async orderProject(project: Project): Promise<void> {
		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Bestelling Project',
				body: `Bent u zeker dat u project "${project.name}" wil bestellen?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'ORDER', 'primary')],
				icon: 'cart',
				type: 'warning'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'ORDER') {
					return;
				}

				this.projectsRepository.updateProjectStatus(project.id, ProjectStatus.IN_PROGRESS)
					.pipe(take(1))
					.subscribe();
			})
	}

	async deleteProject(project: Project): Promise<void> {
		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Verwijderen Project',
				body: `Bent u zeker dat u project "${project.name}" wil verwijderen?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DELETE', 'primary')],
				icon: 'trash',
				type: 'danger'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DELETE') {
					return;
				}

				this.projectsRepository.deleteProject(project.id)
					.pipe(take(1))
					.subscribe(() => this.router.navigate(['/', 'projects']));
			})
	}

	async duplicateProject(project: Project): Promise<void> {
		const dialogRef = this.dialog.open(StandardDialogComponent, {
			data: {
				title: 'Dupliceren Project',
				body: `Bent u zeker dat u project "${project.name}" wil dupliceren?`,
				buttons: [new Button('Annuleren'), new Button('OK', 'DUPLICATE', 'primary')],
				icon: 'duplicate',
				type: 'warning'
			},
		});

		dialogRef
			.afterClosed()
			.subscribe((action) => {
				if (action !== 'DUPLICATE') {
					return;
				}

				this.projectsRepository.duplicateProject(project.id)
					.pipe(take(1))
					.subscribe();
			})
	}

	submitName(): void {
		if (this.editingNameControl.value) {
			const project = {
				name: this.editingNameControl.value,
				accountId: this.project.accountId
			};
			this.projectsRepository.updateProject(this.project.id, project).pipe(take(1))
				.subscribe(() =>
					this.editorStatus = false);
		}
	}

	exitEditState(): void {
		this.editorStatus = false;
	}

	onOpenEditor(): void {
		this.editorStatus = true;
	}
}
