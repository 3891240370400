import { Subject, take } from 'rxjs';
import {
	Box3,
	Group,
	Mesh,
	Vector2,
	Vector3,
	Plane,
} from 'three';

import { EditorRepository } from '../../../modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '../../../modules/projects/store/items/items.repository';
import { PartsRepository } from '../../../modules/projects/store/parts/parts.repository';
import { DesignerMode, PanelType } from '../../../shared/enums';
import { PartPosition } from '../../../shared/shared.types';
import { Item } from '../../../shared/types';

import { Engine } from './engine';
import { GenericItem } from './engine-render.service';
import { HelperFunctions } from './helperFunctions';
import { ObjectBuilder } from './objectBuilder';
import { SelectionManager } from './selectionManager';
import { CollisionManager } from './collisionManager';
import { MovementManager } from './movementManager';
import { TweenManager } from './tweenManager';

const cameraDistance = 24.5;

const default_camera_top_rotation = new Vector3(-Math.PI/2, -Math.PI/2, 0);

export class PartEditor {
	public activeItem?: Group;
	public lockedAxis: 'x' | 'y' | 'z' = 'z';
	public collisionObjects: Array<Group> = [];
	public selectedGroup: Group | null;
	public transformValues: PartPosition[];
	public lastOpenDoors: string[] = [];
	public minPositionValues = {
		x: -7.5,
		y: 0,
		z: -7.5,
	};
	public maxPositionValues = {
		x: 7.5,
		y: 7.5,
		z: 7.5,
	};
	public positions$: Subject<PartPosition[]> = new Subject();

	private mouseVector: Vector2 = new Vector2();

	private plane = new Plane();
	private pNormal = {
		x: new Vector3(1, 0, 0),
		y: new Vector3(0, 1, 0),
		z: new Vector3(0, 0, 1),
	}; // plane's normal
	private planeIntersect = new Vector3(); // point of intersection with the plane
	private pIntersect = new Vector3(); // point of intersection with an object (plane's point)
	private shift = new Vector3(); // distance between position of an object and points of intersection with the object

	collisionManager

	movementManager = new MovementManager(this.mouseVector, this.engine);

	constructor(
		private readonly objectBuilder: ObjectBuilder,
		private readonly engine: Engine,
		private readonly selectionManager: SelectionManager,
		private readonly tweenManager: TweenManager,
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly partsRepository: PartsRepository,
	) {
		this.collisionManager = new CollisionManager(
			this.minPositionValues,
			this.maxPositionValues
		);
	}

	//// Builds the objects for the collision scene
	build(item: Item, hideTimer: number, hideDoors: boolean = false, renderView: string = 'default'): void {
		const finalObject = new Group();
		finalObject.userData.type = 'ObjectGroup';
		finalObject.userData.itemId = item.id;
		finalObject.userData.partId = item.partId;

		let panelGroup: Group;
		(item.articles || []).forEach((article) => {
			panelGroup = this.objectBuilder.buildArticle(
				article,
				true,
				true,
				item.id,
				[]
			);
			finalObject.add(panelGroup);
		});


		const frontPanelGroup = new Group();
		frontPanelGroup.userData.type = 'frontPanelGroup';
		(item.frontArticle?.panels || []).forEach((panel) => {
			const frontPanelMesh = this.objectBuilder.buildItem(panel, true, true, item.id, [], undefined, {
				article: item.frontArticle,
				type: 'frontArticle',
			});
			frontPanelMesh && frontPanelGroup.add(frontPanelMesh);
		});

		finalObject.add(frontPanelGroup);

		(item.fillerZones || []).forEach((zone) => {
			const fillerZoneGroup = this.objectBuilder.buildArticle(zone.article, false, true, item.id, []);

			if (fillerZoneGroup) {
				finalObject.add(fillerZoneGroup);
			}
		});

		if (
			item.partCoordinate?.x ||
			item.partCoordinate?.y ||
			item.partCoordinate?.z ||
			item.partRotation
		) {
			finalObject.userData.lastCorrectPosition = {
				x: item.partCoordinate?.x || 0,
				y: item.partCoordinate?.y || 0,
				z: item.partCoordinate?.z || 0,
			};
			finalObject.userData.hasSavedPosition = true;

			finalObject.rotation.y = item.partRotation.y;
			finalObject.rotation.x = item.partRotation.x;
			finalObject.rotation.z = item.partRotation.z;
			finalObject.position.x = item.partCoordinate?.x || 0;
			finalObject.position.y = item.partCoordinate?.y || 0;
			finalObject.position.z = item.partCoordinate?.z || 0;
		}

		const aabb = new Box3().setFromObject(finalObject as Group);
		const center = aabb.getCenter(new Vector3());
		this.engine.controls.target = center;
		const size = new Vector3();
		aabb.getSize(size);

		if (renderView === 'front') {
			this.engine.camera.position.set(center.x, center.y, 8);
		}

		this.engine.scene.add(finalObject);
		this.collisionObjects.push(finalObject);
		this.engine.startRenderLoop();

		if (hideDoors) {
			this.triggerDoors(true, [], true)
		}

		setTimeout(() => this.positions$.next(this.saveItemPositions()))
	}

	triggerDoors(open: boolean, doorIds: string[], openAllDoors: boolean = false) {
		const allDoors = this.findDoors(this.engine.scene.children);

		if (openAllDoors) {
			return allDoors.forEach((door) => {
				door.userData.disallowSelection = true;
				door.visible = false;
				this.engine.needToRender(50);
			})
		}

		allDoors.forEach((door) => {
			const shouldBeOpen = doorIds.includes(door.userData.object.id);
			const wasOpen = this.lastOpenDoors.includes(door.userData.object.id);

			// If the door should be open but it was open, ignore
			if (shouldBeOpen === wasOpen) {
				return;
			}

			// If the door should be open but it was closed, open it
			if (shouldBeOpen && !wasOpen) {
				door.visible = false;
				// const transparentMaterial = objectDefaultMaterial.clone();
				// transparentMaterial.opacity = 0;
				// transparentMaterial.transparent = true;
				// HelperFunctions.applyTemporaryMaterial([door, ...(door.children as Mesh[])], transparentMaterial);
				this.engine.needToRender(50);
				return;
			}

			// If the door should be closed but it was open, close it
			if (!shouldBeOpen && wasOpen) {
				door.visible = true;
				// HelperFunctions.restoreDefaultMaterial([door, ...(door.children as Mesh[])]);
				this.engine.needToRender(50);
				return;
			}
		});

		this.lastOpenDoors = doorIds;
	}

	disposeCurrentModel(): void {
		for (let i = this.collisionObjects.length - 1; i >= 0; i--) {
			this.collisionObjects[i].traverse((object) => {
				if (!(object as Mesh).isMesh) {
					return;
				}
				// dispose geometry
				(object as Mesh).geometry.dispose();
				// @ts-ignore
				if (object.material.isMaterial) {
					HelperFunctions.cleanMaterial((object as Mesh).material);
				} else {
					// an array of materials
					// @ts-ignore
					for (const material of object.material) {
						this.engine.cleanMaterial(material);
					}
				}
				this.engine.scene.remove(object);
			});
			this.engine.scene.remove(this.collisionObjects[i]);
		}
	}

	addMovementEvents() {
		const movementFunctions = [
			{
				funct: this.selectObject3D,
				eventName: 'pointerdown',
				editor: this,
			},
			{
				funct: this.dragObject,
				eventName: 'pointermove',
				editor: this,
			},
			{
				funct: this.deselectObject3D,
				eventName: 'pointerup',
				editor: this,
			},
			{
				funct: this.doubleClickObject3D,
				eventName: 'dblclick',
				editor: this,
			},
		];
		this.movementManager.addMovementEvents(movementFunctions);

		window.addEventListener('keydown', this.handleKeyDown.bind(this), true)

	}

	removeEvents() {
		this.movementManager?.removeEvents();
		window.removeEventListener('keydown', this.handleKeyDown.bind(this), true)
	}

	handleKeyDown(e: KeyboardEvent) {
		if (e.key !== "Enter") {
			return;
		}

		this.editorRepository.partEditorSelectedItem$
			.pipe(
				take(1),
			)
			.subscribe((itemId) => {
				if (!itemId) {
					return;
				}

				this.editorRepository.setDesignerMode(DesignerMode.ITEM)
				this.editorRepository.clearArticles();
				this.editorRepository.setSelectedItems([]);
				this.partsRepository.activatePart(null);
				this.itemsRepository.activateItem(itemId);
			})
	}

	//// Sets the selected object or removes it based upon the intersection
	selectObject3D(evt) {
		//// Only take into accout left click
		if (evt.which != 1) return;
		this.selectionManager.highlightGroup(false, this.selectedGroup);

		const selectObject = this.selectionManager.getCollisionObject(
			this.mouseVector,
			this.checkGroup
		);
		//// Check selection
		if (
			selectObject &&
			selectObject.object.userData.type == 'ObjectGroup'
		) {
			this.engine.controls.enableRotate = false;

			this.pIntersect.copy(selectObject.intersection.point);
			this.plane.setFromNormalAndCoplanarPoint(
				this.pNormal[this.lockedAxis],
				this.pIntersect
			);
			this.shift.subVectors(
				selectObject.object.position,
				selectObject.intersection.point
			);

			this.activeItem = selectObject.object as Group;
			this.selectedGroup = selectObject.object as Group;
			this.selectionManager.highlightGroup(true, this.selectedGroup);
			this.editorRepository.setSelectedItems([
				selectObject as unknown as GenericItem,
			]);

			this.editorRepository.setPartEditorSelectedItem(selectObject?.object?.userData?.itemId);
		} else {
			this.editorRepository.setSelectedItems([]);
			this.selectedGroup = null;
			this.editorRepository.setPartEditorSelectedItem(null);
		}

		this.engine.needToRender();
	}

	public updateItemPositions(itemPositions: PartPosition[]): void {
		itemPositions.forEach(({ position, rotation, itemId }) => {
			const item = this.engine.scene?.children.find((child) => child.userData.itemId === itemId);

			if (!item) {
				return;
			}

			item.position.x = position.x;
			item.position.y = position.y;
			item.position.z = position.z;

			item.rotation.x = rotation.x;
			item.rotation.y = rotation.y;
			item.rotation.z = rotation.z;

			this.engine.needToRender();
		})
	}

	doubleClickObject3D(e) {
		e.preventDefault();

		const selectObject = this.selectionManager.getCollisionObject(
			this.mouseVector,
			this.checkGroup
		);

		if (!selectObject?.object?.userData?.itemId) {
			return;
		}

		this.editorRepository.setDesignerMode(DesignerMode.ITEM)
		this.editorRepository.clearArticles();
		this.editorRepository.setSelectedItems([]);
		this.partsRepository.activatePart(null);
		this.itemsRepository.activateItem(selectObject.object.userData.itemId);
	}

	//// Removes the active object, enables orbit controls
	deselectObject3D() {
		this.engine.controls.enableRotate = true;
		this.activeItem = null;
	}

	//// Movement function that disables orbit controls
	dragObject(evt) {
		this.movementManager.dragObject(evt);
		//// Check for any active objects
		if (!this.activeItem) return;

		//// Move alongside the locked plane axis
		this.movementManager.moveObject(
			this.plane,
			this.planeIntersect,
			this.shift,
			this.activeItem
		);

		this.collisionManager.checkPosition('x', this.activeItem);
		this.collisionManager.checkPosition('y', this.activeItem);
		this.collisionManager.checkPosition('z', this.activeItem);

		if (
			this.collisionManager.checkCollision(
				this.activeItem,
				this.collisionObjects
			)
		) {
			this.activeItem.position.set(
				this.activeItem.userData.lastCorrectPosition.x,
				this.activeItem.userData.lastCorrectPosition.y,
				this.activeItem.userData.lastCorrectPosition.z
			);
		} else {
			this.activeItem.userData.lastCorrectPosition =
				this.activeItem.position.clone();
		}

		this.positions$.next(this.saveItemPositions());
		this.engine.needToRender();
	}

	//// Returns objects positions and rotation
	saveItemPositions() {
		//// Clear previous entries
		this.transformValues = [];
		//// Save positions and rotation
		for (const object of this.collisionObjects) {
			this.transformValues.push({
				position: {
					x: object.position.x,
					y: object.position.y,
					z: object.position.z,
				},
				rotation: {
					x: object.rotation.x,
					y: object.rotation.y,
					z: object.rotation.z,
				},
				itemId: object.userData.itemId,
				partId: object.userData.partId,
				hasPrefilledPosition: !!object.userData.hasSavedPosition,
			});
		}

		return this.transformValues;
	}

	//// Locks a specific axis for movement
	lockAxis(axis: 'x' | 'y' | 'z') {
		this.lockedAxis = axis;
		this.moveCamera(axis);
	}

	//// Moves the camera to the specified point of view
	moveCamera(axis: 'x' | 'y' | 'z') {


		switch (axis) {
		case 'x':
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				axis,
				cameraDistance,
				1
			);
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				'y',
				0,
				1
			);
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				'z',
				0,
				1
			);
			break;
		case 'y':

			//Orbit controls doesn't allow to animate camera rotation, we need to disable it to fix
			this.engine.controls.enabled = false;
			this.engine.controls.reset();

			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				axis,
				cameraDistance,
				1
			);
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				'z',
				0,
				1
			);
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				'x',
				0,
				1
			);
			this.tweenManager.rotateCamera(
				this.engine.camera,
				default_camera_top_rotation
			);

			break;
		case 'z':
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				axis,
				cameraDistance,
				1
			);
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				'y',
				0,
				1
			);
			this.tweenManager.moveCameraOnAxis(
				this.engine.camera,
				'x',
				0,
				1
			);
			break;
		default:
			break;
		}
	}

	//// Rotate a collision object
	rotateObject(angle) {
		if (!this.selectedGroup) {
			return;
		}

		this.selectedGroup.rotateOnAxis(new Vector3(0, 1, 0), angle);

		if (
			this.collisionManager.checkCollision(
				this.selectedGroup,
				this.collisionObjects
			)
		) {
			this.selectedGroup.rotateOnAxis(new Vector3(0, 1, 0), -angle);
		}

		this.positions$.next(this.saveItemPositions());
		this.engine.needToRender();
	}

	//// Initial placement check recursive function
	checkInitialPlacement() {
		if (this.activeItem.userData.hasSavedPosition) {
			return;
		}

		const addVector = new Vector3(1, 0, 0);
		const collision = this.collisionManager.checkCollision(
			this.activeItem,
			this.collisionObjects
		);
		if (collision) {
			this.activeItem.position.add(addVector);
			this.activeItem.userData.lastCorrectPosition =
				this.activeItem.position.clone();
			this.checkInitialPlacement();
		} else {
			this.activeItem.userData.lastCorrectPosition = {
				x: 0,
				y: 0,
				z: 0,
			};
		}
	}

	arrangeItems(noInitData: boolean, centerAllObjects: boolean) {
		if (noInitData) {
			for (let i = 0; i < this.collisionObjects.length; i++) {
				this.activeItem = this.collisionObjects[i];
				this.checkInitialPlacement();
			}
		}

		//// Checks if it should center the objects
		if (centerAllObjects) {
			console.log('center objects', this.collisionObjects);
			HelperFunctions.centerGroups(
				HelperFunctions.getSceneDimensions(this.collisionObjects) *
					0.65,
				this.collisionObjects
			);
		}

		this.activeItem = null;
		this.engine.needToRender(100);
	}

	checkGroup(object) {
		return object.parent?.parent?.userData?.type == 'ObjectGroup';
	}

	private findDoors(children: any[]): Mesh[] {
		return children.reduce((acc, group) => {
			const childrenDoors = this.findDoors(group.children || []);

			if ([PanelType.FRONT, PanelType.DRAWER_FRONT].includes(group?.userData?.object?.panelType)) {
				return [...acc, ...childrenDoors, group];
			}

			return [...acc, ...childrenDoors]
		}, []);
	}
}
