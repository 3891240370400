import {
	Box3,
	BufferAttribute,
	BufferGeometry,
	Color,
	EdgesGeometry,
	Float32BufferAttribute,
	FrontSide,
	LineBasicMaterial,
	LineSegments,
	Material,
	Mesh,
	MeshStandardMaterial, PerspectiveCamera,
	ShaderMaterial,
	Vector3
} from "three";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry.js";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial.js";
import { Line2 } from "three/examples/jsm/lines/Line2.js";

import { FaceNormal, IEdge, MeshMaterial } from "~shared/types";
import { IPolyhedron } from "~shared/types/polyhedron.types";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

const layoutLineScaleValue = 0.999;

export function cleanMaterial(material) {
	// dispose material
	material.dispose();
	// dispose textures
	for (const key of Object.keys(material)) {
		const value = material[key];
		if (value && typeof value === "object" && "minFilter" in value) {
			value.dispose();
		}
	}
}

/**
 * @param {Array<BufferAttribute>} attributes
 * @return {BufferAttribute}
 */
export function mergeBufferAttributes(attributes) {
	let TypedArray;
	let itemSize;
	let normalized;
	let arrayLength = 0;

	for (let i = 0; i < attributes.length; ++i) {
		const attribute = attributes[i];

		if (attribute.isInterleavedBufferAttribute) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferAttributes() failed. InterleavedBufferAttributes are not supported."
			);
			return null;
		}

		if (TypedArray === undefined)
			TypedArray = attribute.array.constructor;
		if (TypedArray !== attribute.array.constructor) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferAttributes() failed. BufferAttribute.array must be of consistent array types across matching attributes."
			);
			return null;
		}

		if (itemSize === undefined) itemSize = attribute.itemSize;
		if (itemSize !== attribute.itemSize) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferAttributes() failed. BufferAttribute.itemSize must be consistent across matching attributes."
			);
			return null;
		}

		if (normalized === undefined) normalized = attribute.normalized;
		if (normalized !== attribute.normalized) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferAttributes() failed. BufferAttribute.normalized must be consistent across matching attributes."
			);
			return null;
		}

		arrayLength += attribute.array.length;
	}

	const array = new TypedArray(arrayLength);
	let offset = 0;

	for (let i = 0; i < attributes.length; ++i) {
		array.set(attributes[i].array, offset);

		offset += attributes[i].array.length;
	}

	return new BufferAttribute(array, itemSize, normalized);
}

export function mergeBufferGeometries(geometries, useGroups = false) {
	if (geometries.length === 0) {
		return new BufferGeometry();
	}

	const isIndexed = geometries[0].index !== null;

	const attributesUsed = new Set(Object.keys(geometries[0].attributes));
	const morphAttributesUsed = new Set(
		Object.keys(geometries[0].morphAttributes)
	);

	const attributes = {};
	const morphAttributes = {};

	const morphTargetsRelative = geometries[0].morphTargetsRelative;

	const mergedGeometry = new BufferGeometry();

	let offset = 0;

	for (let i = 0; i < geometries.length; ++i) {
		const geometry = geometries[i];
		let attributesCount = 0;

		// ensure that all geometries are indexed, or none

		if (isIndexed !== (geometry.index !== null)) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed with geometry at index " +
				i +
				". All geometries must have compatible attributes; make sure index attribute exists among all geometries, or in none of them."
			);
			return null;
		}

		// gather attributes, exit early if they're different

		for (const name in geometry.attributes) {
			if (!attributesUsed.has(name)) {
				console.error(
					"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed with geometry at index " +
					i +
					". All geometries must have compatible attributes; make sure \"" +
					name +
					"\" attribute exists among all geometries, or in none of them."
				);
				return null;
			}

			if (attributes[name] === undefined) attributes[name] = [];

			attributes[name].push(geometry.attributes[name]);

			attributesCount++;
		}

		// ensure geometries have the same number of attributes

		if (attributesCount !== attributesUsed.size) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed with geometry at index " +
				i +
				". Make sure all geometries have the same number of attributes."
			);
			return null;
		}

		// gather morph attributes, exit early if they're different

		if (morphTargetsRelative !== geometry.morphTargetsRelative) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed with geometry at index " +
				i +
				". .morphTargetsRelative must be consistent throughout all geometries."
			);
			return null;
		}

		for (const name in geometry.morphAttributes) {
			if (!morphAttributesUsed.has(name)) {
				console.error(
					"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed with geometry at index " +
					i +
					".  .morphAttributes must be consistent throughout all geometries."
				);
				return null;
			}

			if (morphAttributes[name] === undefined)
				morphAttributes[name] = [];

			morphAttributes[name].push(geometry.morphAttributes[name]);
		}

		// gather .userData

		mergedGeometry.userData.mergedUserData =
			mergedGeometry.userData.mergedUserData || [];
		mergedGeometry.userData.mergedUserData.push(geometry.userData);

		if (useGroups) {
			let count;

			if (isIndexed) {
				count = geometry.index.count;
			} else if (geometry.attributes.position !== undefined) {
				count = geometry.attributes.position.count;
			} else {
				console.error(
					"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed with geometry at index " +
					i +
					". The geometry must have either an index or a position attribute"
				);
				return null;
			}

			mergedGeometry.addGroup(offset, count, i);

			offset += count;
		}
	}

	// merge indices

	if (isIndexed) {
		let indexOffset = 0;
		const mergedIndex = [];

		for (let i = 0; i < geometries.length; ++i) {
			const index = geometries[i].index;

			for (let j = 0; j < index.count; ++j) {
				mergedIndex.push(index.getX(j) + indexOffset);
			}

			indexOffset += geometries[i].attributes.position.count;
		}

		mergedGeometry.setIndex(mergedIndex);
	}

	// merge attributes

	for (const name in attributes) {
		const mergedAttribute = mergeBufferAttributes(
			attributes[name]
		);

		if (!mergedAttribute) {
			console.error(
				"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed while trying to merge the " +
				name +
				" attribute."
			);
			return null;
		}

		mergedGeometry.setAttribute(name, mergedAttribute);
	}

	// merge morph attributes

	for (const name in morphAttributes) {
		const numMorphTargets = morphAttributes[name][0].length;

		if (numMorphTargets === 0) break;

		mergedGeometry.morphAttributes =
			mergedGeometry.morphAttributes || {};
		mergedGeometry.morphAttributes[name] = [];

		for (let i = 0; i < numMorphTargets; ++i) {
			const morphAttributesToMerge = [];

			for (let j = 0; j < morphAttributes[name].length; ++j) {
				morphAttributesToMerge.push(morphAttributes[name][j][i]);
			}

			const mergedMorphAttribute = this.mergeBufferAttributes(
				morphAttributesToMerge
			);

			if (!mergedMorphAttribute) {
				console.error(
					"THREE.BufferGeometryUtils: .mergeBufferGeometries() failed while trying to merge the " +
					name +
					" morphAttribute."
				);
				return null;
			}

			mergedGeometry.morphAttributes[name].push(mergedMorphAttribute);
		}
	}

	return mergedGeometry;
}

export function bufferMaterialMap(
	options: Partial<MeshMaterial>
): MeshStandardMaterial {
	// selection highlight both object and it's parent group
	return new MeshStandardMaterial({
		color: options.colour,
		transparent: (options.opacity ?? 1) < 1,
		opacity: options.opacity ?? 1
	});
}

export function createLines(objGeometry: BufferGeometry, innerLine: boolean) {
	const geometry = objGeometry.clone();
	let edges = new EdgesGeometry(geometry);

	const material = new LineBasicMaterial({
		color: 0x000000,
		linewidth: 2
	});

	const lines = new LineSegments(edges, material);
	lines.userData.isEdgeLine = true;
	lines.renderOrder = 1;

	if (innerLine) {
		lines.userData.defaultMaterial = material;
		const center = new Vector3();
		lines.geometry.computeBoundingBox();
		lines.geometry.boundingBox.getCenter(center);
		lines.geometry.center();
		lines.scale.set(
			layoutLineScaleValue,
			layoutLineScaleValue,
			layoutLineScaleValue
		);
		lines.position.copy(center);
	}

	lines.children.forEach((line) => line.userData.defaultMaterial = material);

	return lines;
}

export function createArticleZoneBackFace(articleZone, isolationId): Mesh[] {
	let meshes = [];

	for (let i = 0; i < articleZone.shape.coordinates.length; i++) {
		let tempGeom = [];
		const geometryArr = [];

		for (let j = 0; j < articleZone.shape.coordinates[i].vertices.length; j++) {
			if (["BK"].includes(articleZone.shape.coordinates[i].faceNormal)) {
				const geometry = new BufferGeometry();
				const positions = [
					articleZone.shape.coordinates[i].vertices[j][0][0],
					articleZone.shape.coordinates[i].vertices[j][0][1],
					articleZone.shape.coordinates[i].vertices[j][0][2], // v1
					articleZone.shape.coordinates[i].vertices[j][1][0],
					articleZone.shape.coordinates[i].vertices[j][1][1],
					articleZone.shape.coordinates[i].vertices[j][1][2], // v2
					articleZone.shape.coordinates[i].vertices[j][2][0],
					articleZone.shape.coordinates[i].vertices[j][2][1],
					articleZone.shape.coordinates[i].vertices[j][2][2] // v3
				];

				geometry.setAttribute(
					"position",
					new Float32BufferAttribute(positions, 3)
				);
				geometry.computeVertexNormals();
				geometryArr.push(geometry);
				tempGeom.push(geometry);
			}
		}

		const geometryFinal: BufferGeometry =
			mergeBufferGeometries(geometryArr);

		let material = new MeshStandardMaterial({
			color: 0xff00ff,
			transparent: false,
			opacity: 1
		});

		geometryFinal.computeVertexNormals();
		geometryFinal.computeBoundingBox();
		geometryFinal.computeBoundingSphere();
		const mesh = new Mesh(geometryFinal, material);
		mesh.userData.type = "face";
		mesh.userData.object = {
			id: isolationId,
			faceNormal: articleZone.shape.coordinates[i].faceNormal,
			articleZone: articleZone,
			faceIdx: i,
			type: "articleZoneBackFace",
			mesh: mesh,
			articleZoneBackFace: true
		};
		mesh.userData.isolationId = isolationId;

		meshes.push(mesh);
	}

	return meshes;
}

export function createEdgebandFaces(shape: IPolyhedron, isolationId, edges: IEdge[], shouldBeIsolated: boolean): Mesh[] {
	let meshes = [];

	for (let i = 0; i < shape.coordinates.length; i++) {
		let tempGeom = [];
		const geometryArr = [];

		for (let j = 0; j < shape.coordinates[i].vertices.length; j++) {
			const geometry = new BufferGeometry();
			const positions = [
				shape.coordinates[i].vertices[j][0][0],
				shape.coordinates[i].vertices[j][0][1],
				shape.coordinates[i].vertices[j][0][2], // v1
				shape.coordinates[i].vertices[j][1][0],
				shape.coordinates[i].vertices[j][1][1],
				shape.coordinates[i].vertices[j][1][2], // v2
				shape.coordinates[i].vertices[j][2][0],
				shape.coordinates[i].vertices[j][2][1],
				shape.coordinates[i].vertices[j][2][2] // v3
			];

			geometry.setAttribute(
				"position",
				new Float32BufferAttribute(positions, 3)
			);
			geometry.computeVertexNormals();
			geometryArr.push(geometry);
			tempGeom.push(geometry);
		}

		const geometryFinal: BufferGeometry =
			mergeBufferGeometries(geometryArr);

		const edge = findEdge(edges, shape.coordinates[i].faceNormal);
		if (!shape?.mesh?.colour && !shape.coordinates[i]?.mesh?.colour) {
			console.error("missing mesh colour and face colour", shape);
		}
		// const isEdgeband = edge?.edgeband === false;
		// const materialColor = isEdgeband ? 0x754510 : shape.mesh.colour;

		let material = new MeshStandardMaterial({
			color: shape?.mesh?.colour || shape.coordinates[i]?.mesh?.colour || "#fff",
			transparent: shouldBeIsolated ? true : false,
			opacity: shouldBeIsolated ? 0.15 : 1
		});

		geometryFinal.computeVertexNormals();
		geometryFinal.computeBoundingBox();
		geometryFinal.computeBoundingSphere();
		const mesh = new Mesh(geometryFinal, material);
		mesh.userData.isClickableFace = true;
		mesh.userData.type = "face";
		mesh.userData.object = {
			id: isolationId,
			faceNormal: shape.coordinates[i].faceNormal,
			faceIndex: i,
			type: "face",
			mesh: mesh,
			edgeband: edge?.edgeband || false,
			colour: shape?.mesh?.colour || shape.coordinates[i]?.mesh?.colour || "#fff"
			// ...(edge?.edgeband && { colour: shape.mesh.colour })
		};
		mesh.userData.isolationId = isolationId;

		if (edge?.edgeband !== undefined) {
			meshes.push(mesh);
		}
	}

	return meshes;
}

export function findEdge(edges: IEdge[], faceNormal: FaceNormal): IEdge {
	return (edges || []).find((edge) => edge.faceNormal === faceNormal);
}

export function getSceneDimensions(objects) {
	let highestValue = 0;
	for (const group of objects) {
		if (group.position.x > highestValue)
			highestValue = group.position.x;
	}

	return highestValue;
}

export function centerGroups(value: number, objects) {
	for (const group of objects) {
		group.position.x -= value;
	}
}

export function customisedMaterialMap(options: Partial<MeshMaterial>) {
	return new ShaderMaterial({
		uniforms: {
			baseColor: { value: new Color(options.colour) },
			bars: { value: 0 } // 0 - x, 1 - y, 2 - both
		},
		vertexShader: `
				varying vec4 vPos;

				void main() {
					vPos = modelMatrix * vec4( position, 1.0 );
					gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
				}
			`,
		fragmentShader: `
				uniform vec3 baseColor;
				uniform float bars;

				varying vec4 vPos;

				void main() {
					vec4 lineColor = vec4(1.0, 0.0, 0.0, 1.0);

					float interval = 0.15;
					float a = step(mod(vPos.x + vPos.y + vPos.z, interval) / (interval - 0.1), 0.1);

					if (a == 0.0) {
						gl_FragColor = vec4(baseColor, ${options.opacity});
					} else {
						gl_FragColor = lineColor;
					}
				}
			`,
		side: FrontSide,
		transparent: options.transparent
	});
}

export function applyTemporaryMaterial(meshes: Mesh[], material: Material): void {
	meshes.forEach((mesh) => {
		if (!mesh.userData.defaultMaterial) {
			console.warn("[UNSUP] No default material was found when applying a temporary one, setting default one right now for", mesh);
			mesh.userData.defaultMaterial = mesh.material;
		}

		mesh.userData.temporaryMaterial = material;
		mesh.material = material;
	});
}

export function restoreDefaultMaterial(meshes: Mesh[]): void {
	meshes.forEach((mesh) => {
		const defaultMaterial = mesh.userData?.defaultMaterial;

		if (!mesh.material) {
			return;
		}

		if (!defaultMaterial) {
			console.warn("[UNSUP] No default material to restore found for", mesh);
			return;
		}

		delete mesh.userData?.temporaryMaterial;
		mesh.material = defaultMaterial;
	});
}

export function fitCameraToObject(camera: PerspectiveCamera, bbox: Box3, offset: number, controls: OrbitControls) {
	offset = offset || 0.5;

	// const boundingBox = new Box3();

	// // get bounding box of object - this will be used to setup controls and camera
	// boundingBox.setFromObject( object );
	const center = new Vector3();
	bbox.getCenter(center);

	const size = new Vector3();
	bbox.getSize(size);

	// get the max side of the bounding box (fits to width OR height as needed )
	const maxDim = Math.max( size.x, size.y, size.z );
	const fov = camera.fov * ( Math.PI / 180 );
	let cameraZ = Math.abs( maxDim / 4 * Math.tan( fov * 2 ) );

	cameraZ *= offset; // zoom out a little so that objects don't fill the screen

	camera.position.z = cameraZ;

	const minZ = bbox.min.z;
	const cameraToFarEdge = ( minZ < 0 ) ? -minZ + cameraZ : cameraZ - minZ;

	camera.far = cameraToFarEdge * 3;
	camera.updateProjectionMatrix();

	if ( controls ) {

		// set camera to rotate around center of loaded object
		controls.target = center;

		// prevent camera from zooming out far enough to create far plane cutoff
		controls.maxDistance = cameraToFarEdge * 2;

		controls.saveState();

	} else {
		camera.lookAt( center )
	}
}
