import {
	Mesh,
	BufferGeometry,
	Float32BufferAttribute,
	Group,
} from 'three';

import { IArticle, Item, IArticleZone, IPanel, IArticleHardware } from '~shared/types';
import { PanelType, ThreeObjectTypeEnum } from '~shared/enums';
import { EDITOR_STATE_COLOURS } from '~shared/shared.const';
import { IPolyhedron } from '~shared/types/polyhedron.types';
import { EditorMode } from '~modules/projects/store/editor/editor.types';

import { HelperFunctions } from './helperFunctions';
import { Loader } from './loader';
import { GenericItem } from './engine-render.service';
import { Engine } from './engine';

export class ObjectBuilder {
	public loader: Loader = new Loader();

	constructor(private readonly renderService: Engine) {}

	private getColour(item: IPanel | IArticleZone): string {
		return (item as any)?.mesh?.colour || (item as IPanel)?.board?.colour || '#FFFFFF';
	}

	public buildItem(
		item: IPanel | IArticleZone,
		innerLines: boolean,
		addLines: boolean,
		id: any,
		transparentPanelTypes: PanelType[],
		isolatedItem?: GenericItem,
		extraData?: any
	): Mesh | void {
		if (!item.shape) {
			return console.trace('item missing shape property');
		}

		const colour = this.getColour(item);

		const shouldBeTransparent = (transparentPanelTypes || [])?.includes((item as IPanel).panelType)
		const shouldBeIsolated = (isolatedItem?.id && isolatedItem.id !== item.id);

		const material = !!(item as IPanel)?.customisations?.length
			? HelperFunctions.customisedMaterialMap({
				...(item as any).mesh,
				colour: colour,
				...(shouldBeTransparent && { opacity: 0.5, transparent: true }),
				...(shouldBeIsolated && { opacity: 0.15, transparent: true })
			  })
			: HelperFunctions.bufferMaterialMap({
				...(item as any).mesh,
				colour: colour,
				...(shouldBeTransparent && { opacity: 0.5, transparent: true }),
				...(shouldBeIsolated && { opacity: 0.15, transparent: true })
			  });

		const obj = this.generateShape(
			{
				...item,
				...extraData,
				colour: colour
			},
			ThreeObjectTypeEnum.PANEL,
			item.shape,
			material
		);

		const handle = (item as GenericItem).handleConnection;

		if (handle) {
			this.buildHardware(obj, (item as GenericItem).handleConnection.hardware);
		}

		if (addLines) {
			const lines = HelperFunctions.createLines(obj.geometry, innerLines);
			obj.add(lines);
		}

		if ((item as IPanel).edges) {
			const faces = HelperFunctions.createEdgebandFaces(item.shape as IPolyhedron, item.id, (item as IPanel).edges, shouldBeIsolated);
			faces.forEach((face) => obj.add(face));
		}

		obj.userData.itemId = id;
		return obj;
	}

	public buildPolyhedron(
		polyhedra: IPolyhedron,
		innerLines: boolean,
		addLines: boolean,
		id: any,
		extraData?: any
	): Mesh | void {
		if (!polyhedra.coordinates) {
			return console.trace('polyhedra missing coordinates property');
		}

		const colour = polyhedra.mesh.colour;


		const material = HelperFunctions.bufferMaterialMap({
			...polyhedra.mesh,
			colour: colour,
		  })

		const obj = this.generateShape(
			{
				...polyhedra,
				...extraData,
				colour: colour
			},
			ThreeObjectTypeEnum.PANEL,
			polyhedra,
			material
		);

		if (addLines) {
			const lines = HelperFunctions.createLines(obj.geometry, innerLines);
			obj.add(lines);
		}

		obj.userData.itemId = id;
		return obj;
	}

	public buildArticle(
		article: IArticle,
		innerLines: boolean,
		addLines: boolean,
		id: string,
		transparentPanelTypes: PanelType[],
		isolatedItem?: GenericItem,
		extraData?: any
	): Group {
		if (!article) {
			return;
		}

		const group = new Group();
		group.userData.type = 'articleGroup';
		(article.panels || []).forEach((panel) => {
			const obj = this.buildItem(panel, innerLines, addLines, id, transparentPanelTypes, isolatedItem, {
				...extraData,
				article,
			});

			obj && group.add(obj);
		});


		(article.base?.panels || []).forEach((panel) => {
			const obj = this.buildItem(panel, innerLines, addLines, id, transparentPanelTypes, isolatedItem, {
				...extraData,
				article,
			});

			obj && group.add(obj);
		});

		(article.polyhedra || []).forEach((polyhedron) => {
			const obj = this.buildPolyhedron(polyhedron, innerLines, addLines, id, {
				...extraData,
				article,
			});

			obj && group.add(obj);
		});

		this.buildHardware(group, (article.hardware || []), article);

		return group;
	}

	private generateShape(
		userData: Object,
		type: ThreeObjectTypeEnum,
		shape,
		material,
	): Mesh {
		const geometryArr = [];
		let tempGeom = [];

		for (let i = 0; i < shape.coordinates.length; i++) {
			for (let j = 0; j < shape.coordinates[i].vertices.length; j++) {
				const geometry = new BufferGeometry();
				const positions = [
					shape.coordinates[i].vertices[j][0][0],
					shape.coordinates[i].vertices[j][0][1],
					shape.coordinates[i].vertices[j][0][2], // v1
					shape.coordinates[i].vertices[j][1][0],
					shape.coordinates[i].vertices[j][1][1],
					shape.coordinates[i].vertices[j][1][2], // v2
					shape.coordinates[i].vertices[j][2][0],
					shape.coordinates[i].vertices[j][2][1],
					shape.coordinates[i].vertices[j][2][2], // v3
				];

				geometry.setAttribute(
					'position',
					new Float32BufferAttribute(positions, 3)
				);
				geometry.computeVertexNormals();
				geometryArr.push(geometry);
				tempGeom.push(geometry);
			}
		}

		const geometryFinal: BufferGeometry =
			HelperFunctions.mergeBufferGeometries(geometryArr);
		geometryFinal.computeBoundingBox();
		geometryFinal.computeBoundingSphere();
		const mesh = new Mesh(geometryFinal, material);
		mesh.name = userData['id'];
		mesh.castShadow = true;
		mesh.userData.type = type;
		mesh.userData.object = userData;
		mesh.userData.defaultMaterial = material;

		return mesh;
	}

	private deg2rad(degrees: number): number {
		return degrees * Math.PI / 180
	}

	private buildHardware(
		parent,
		hardware: IArticleHardware[],
		article: any = {},
	): Promise<void> {
		return new Promise((resolve) => {
			hardware.forEach((hardwareItem) => {
				hardwareItem.objPositions.forEach((objPosition) => {
					const loader = new Loader();

					loader.loadFile(
						`assets/media/obj/${hardwareItem.catalogItem.hardwareType.toLowerCase()}/${objPosition.objLabel}`,
						{},
						parent,
						hardwareItem.variant.colour,
						(object, details, parent) => {
							object.castShadow = true;
							object.receiveShadow = true;

							object.position.set(
								Number(objPosition.position.x),
								Number(objPosition.position.y),
								Number(objPosition.position.z),
							);
							object.rotation.set(
								this.deg2rad(Number(objPosition.rotation.x)),
								this.deg2rad(Number(objPosition.rotation.y)),
								this.deg2rad(Number(objPosition.rotation.z)),
							)
							if (objPosition.scale) {
								object.scale.set(
									Number(objPosition.scale.x) * 1,
									Number(objPosition.scale.y) * 1,
									Number(objPosition.scale.z) * 1,
								);
							}

							object.userData = {
								type: hardwareItem.catalogItem.hardwareType.toLowerCase(),
								customObj: true,
								article,
								object: {...hardwareItem, type: hardwareItem.catalogItem.hardwareType.toLowerCase(), article},
							}

							parent.add(object);

							this.renderService.needToRender(20)
						})
					resolve();
				}
				);
			})
		})
	}

	public buildCabinet(item: Item, transparentPanelTypes: PanelType[], isolatedItem: GenericItem, editorMode: EditorMode) {
		const panelGroup: Group = new Group();
		const baseGroups: Array<Group> = [];
		const fillerGroups: Array<Group> = [];

		(item.articles || []).forEach((article) => {
			const group = this.buildArticle(article, false, true, item.id, transparentPanelTypes, isolatedItem);

			if (!group) {
				return;
			}

			panelGroup.add(group);
		});

		(item.fillerZones || []).forEach((zone) => {
			const fillerZoneGroup = this.buildArticle(zone.article, false, true, item.id, transparentPanelTypes, isolatedItem, {
				outlineFaceIdx: zone.outlineFaceIdx,
				outlineFaceNormal: zone.outlineFaceNormal,
			});

			fillerZoneGroup && fillerGroups.push(fillerZoneGroup);
		});

		const articleZoneGroup = new Group();
		articleZoneGroup.userData.type = 'articleZoneGroup';

		(item.articleZones || []).forEach((articleZone) => {
			if (!articleZone.isVisible && editorMode === EditorMode.DEFAULT) {
				return;
			}

			const articleZoneMesh = this.buildItem({
				...articleZone,
				mesh: {
					transparent: true,
					opacity: editorMode === EditorMode.DEFAULT ? 0.2 : 0.2,
					component: 'LAYOUT',
					colour: editorMode === EditorMode.DEFAULT ? undefined : EDITOR_STATE_COLOURS.ARTICLE_ZONE_SELECTION_CLICKABLE_ZONES,
				}
			}, true, true, null, [], isolatedItem, {
				articleZone,
			});

			const faces = HelperFunctions.createArticleZoneBackFace(articleZone, item.id);
			faces.forEach((face) => articleZoneGroup.add(face));

			articleZoneMesh && articleZoneGroup.add(articleZoneMesh);
		});

		return [panelGroup, baseGroups, articleZoneGroup, fillerGroups];
	}
}
