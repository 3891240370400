import { Component, Input, EventEmitter } from '@angular/core';

import {EditorRepository} from "~modules/projects/store/editor/editor.repository";

@Component({
	selector: 'app-button-controller',
	templateUrl: './button-controller.component.html',
	styleUrls: ['./button-controller.component.scss'],
})
export class AddCabinetButtonComponent {
	public eventEmitter: EventEmitter<any>;
	private internalData: any;
	constructor(
		private editorRepository: EditorRepository
	) {}
	@Input() set data(data: any) {
		this.internalData = data;
	}

	get data(): any {
		return this.internalData;
	}

	onClick(): void {
		this.editorRepository.triggerCabinetCreation$.next(true);
	}
}
