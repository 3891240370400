import {
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	FormBuilder,
	FormGroup,
} from '@angular/forms';
import { filter, Observable, Subject, switchMap, takeUntil, take, map, combineLatest, tap, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { OutlineType } from '~shared/enums';
import { Outline, OutlineSet } from '~shared/types';
import { OutlineSetsRepository } from '~modules/projects/store/outline-sets/outline-sets.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { aggregateDimensions } from '~modules/projects/helpers/aggregateDimensions';

import { OutlineTranslationMap } from './outline-controller.types';

@Component({
	selector: 'app-outline-controller',
	templateUrl: './outline-controller.component.html'
})
export class OutlineControllerComponent implements OnInit, OnDestroy {
	@Input() data: any;

	public outlineFormGroup: FormGroup;
	public disableFields$: Observable<boolean>
	public outlineMap$: Observable<Record<OutlineType, Outline>>;
	public outlineTranslationMap = OutlineTranslationMap;

	private componentDestroyed$: Subject<boolean> = new Subject();

	constructor(
		private readonly outlineSetsRepository: OutlineSetsRepository,
		private readonly fb: FormBuilder,
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly toastrService: ToastrService
	) {}

	ngOnInit(): void {
		this.outlineMap$ = this.editorRepository.outlineMap$;
		this.disableFields$ = this.editorRepository.disableFields$;

		this.outlineMap$
			.pipe(
				filter((outlineMap) => !!outlineMap),
				takeUntil(this.componentDestroyed$),
				switchMap((outlineMap) => {
					return this.itemsRepository.activeItem$
						.pipe(
							take(1),
							map((item) => ({ outlineMap, item }))
						)
				})
			)
			.subscribe(({ outlineMap, item }) => {
				const dimensionValues = {
					...item.outline?.dimension?.height,
					...item.outline?.dimension?.width,
					...item.outline?.dimension?.depth,
				}

				this.outlineFormGroup = this.fb.group(Object.keys(outlineMap).reduce((acc, outlineType: OutlineType) => {
					const outline = outlineMap[outlineType];

					return {
						...acc,
						...outline.dimensions
							.filter((dimension) => !['H', 'D', 'W'].includes(dimension.label))
							.reduce((dimAcc, dimension) => ({ ...dimAcc, [dimension.label]: [dimensionValues[dimension.label]?.value || null] }), {})
					}
				}, {} as Record<OutlineType, FormGroup>));

				this.outlineFormGroup.valueChanges
					.pipe(takeUntil(this.componentDestroyed$))
					.subscribe(this.handleFormChanges.bind(this))
			})

		this.itemsRepository.activeItem$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((item) => !!item),
				switchMap((item) => {
					return this.outlineSetsRepository.getOutlineSetByPrototypeLabel(item.outline?.frontOutline, item.outline?.sideOutline, item.outline?.topOutline);
				}),
				filter((outlineSet) => {
					return !!outlineSet;
				}),
			)
			.subscribe((outlineSet: OutlineSet) => {
				this.editorRepository.setOutlineMap({
					[OutlineType.FRONT]: outlineSet.frontOutline as Outline,
					[OutlineType.SIDE]: outlineSet.sideOutline as Outline,
					[OutlineType.TOP]: outlineSet.topOutline as Outline
				});
			});

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => disabled ? this.outlineFormGroup?.disable({ emitEvent: false }) : this.outlineFormGroup?.enable({ emitEvent: false }));
	}

	public submitForm(): void {
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.dimensions$])
			.pipe(
				take(1),
				tap(() => this.editorRepository.setLoading(true)),
				switchMap(([item, dimensions]) => {
					if (item.id !== 'new') {
						return this.itemsRepository.updateItemDimension(item.id, item.partId, aggregateDimensions({ dimensions }, item).dimension)
							.pipe(take(1));
					}
				}),
				catchError((err) => {
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				})
			)
			.subscribe(() => this.editorRepository.setLoading(false))
	}

	public trackByFn(index, item): void {
		return index;
	}

	private handleFormChanges(formValues): void {
		this.editorRepository.setDimensions(formValues)
		this.editorRepository.updateRequired$.next(true);
	}

	public handleButtonClick(type: string): void {
		this.itemsRepository.activeItem$
			.pipe(take(1))
			.subscribe((item) => {
				if (item.id === 'new') {
					return this.editorRepository.setActiveConfigurationPanel(type)
				}

				this.toastrService.error('Grondvormen kunnen enkel gekozen worden bij het aanmaken van een kastelement')
			})
	}

	public keyEnterHandler(): void {
		if (this.outlineFormGroup.valid) {
			this.editorRepository.triggerCabinetCreation$.next(true);
		}
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete()
	}
}
