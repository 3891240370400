import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { catchError, combineLatest, filter, map, Observable, Subject, switchMap, take, takeUntil, throwError } from 'rxjs';

import { DivisionMode, PanelType, PlaneAxis } from '~shared/enums/item.enum';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { ExpressionValidators } from '~shared/validators/expression';
import { Item } from '~shared/types';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';

@Component({
	templateUrl: './vertical-divider.component.html',
})
export class VerticalDividerComponent implements OnInit, OnDestroy {
	public configurationForm: FormGroup<{
		id: FormControl<string>;
		expression: FormControl<string>;
		divisionMode: FormControl<DivisionMode>;
	}>;
	public includeDividerDisabled = false;
	public openItems: Record<string, boolean> = {
		GENERAL: true
	};

	private componentDestroyed$: Subject<boolean> = new Subject()
	public activePanel$: Observable<GenericItem>;
	public activeItem$: Observable<Item>;

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly fb: FormBuilder
	) {}

	@HostListener('document:keydown.escape')
	public onKeydownHandler() {
		this.initializeDefaults()
	}

	public ngOnInit(): void {
		this.activeItem$ = this.itemsRepository.activeItem$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));
		this.configurationForm = this.fb.group({
			id: ['new'],
			expression: ['', [Validators.required, ExpressionValidators.checkExpression()]],
			divisionMode: ['INCLUDE_DIVIDER' as DivisionMode],
		});

		this.configurationForm.get('expression').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((expression) => {
				if (new RegExp(/(\:0(mm)?\:)|(^0(mm)?\:)/).test(expression)) {
					this.includeDividerDisabled = true;
				} else {
					this.includeDividerDisabled = false;
				}
			})

		this.configurationForm.get('divisionMode').valueChanges
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe(() => setTimeout(() => this.submitForm()))

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => disabled
				? this.configurationForm.disable({ emitEvent: false })
				: this.configurationForm.enable({ emitEvent: false }))

		this.initializeDefaults();
	}

	public submitForm(): void {
		const { value } = this.configurationForm;

		if (value.id === 'new') {
			return this.createItem();
		}

		this.editorRepository.setLoading(true);
		this.itemsRepository.activeItem$
			.pipe(
				take(1),
				switchMap((item) => this.itemsRepository.updateDivider(item.partId, item.id, value.id, {
					linearDivision: {
						divisionMode: value.divisionMode,
						expression: value.expression,
					},
					axis: PlaneAxis.X
				}).pipe(take(1))),
				catchError((err) => {
					this.editorRepository.setLoading(false);
					return throwError(() => err);
				}),
				switchMap((updatedArticle) => this.editorRepository.selectedItems$.pipe(take(1), map((selectedItems) => ({ selectedItems, updatedArticle }))))
			)
			.subscribe(({ updatedArticle, selectedItems }) => {
				this.editorRepository.setLoading(false);
				this.editorRepository.setSelectedItems(selectedItems.map((item) => ({ ...item, article: updatedArticle })));
			})
	}

	public populateExpression(expression: string): void {
		this.configurationForm.patchValue({
			expression
		});
		this.submitForm();
	}

	public createItem(): void {
		this.configurationForm.markAllAsTouched();

		if (!this.configurationForm.valid) {
			return;
		}

		const { value } = this.configurationForm;
		this.editorRepository.setLoading(true);
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedArticleZones$])
			.pipe(
				take(1),
				switchMap(([item, [articleZone]]) => this.itemsRepository.createDivider(item.partId, item.id, articleZone?.id || item.articleZones[0].id, {
					linearDivision: {
						divisionMode: value.divisionMode,
						expression: value.expression,
					},
					axis: PlaneAxis.X
				})),
				catchError((err) => {
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				}),
			)
			.subscribe(([article]) => {
				const selectedPanel = article.panels.find(({ panelType }) => panelType === PanelType.DIVIDER);
				this.editorRepository.setLoading(false);
				this.editorRepository.setSelectedItems([{ ...selectedPanel, article }]);
			})
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public initializeDefaults(): void {
		this.editorRepository.selectedArticles$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((articles) => !!articles && !!articles.length)
			)
			.subscribe(([article]) => {
				this.configurationForm.reset({}, { emitEvent: false });
				this.configurationForm.patchValue({
					id: article.id,
					expression: article.linearDivision.expression,
					divisionMode: article.linearDivision.divisionMode,
				}, { emitEvent: false });
			})
	}
}
