import {combineLatest, forkJoin, Observable, of, Subject, throwError} from "rxjs";
import { switchMap, take, takeUntil, map, catchError, filter} from 'rxjs/operators';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { findUpdatedPanel } from '~shared/helpers'
import { IArticle, Item } from '~shared/types';
import { ParameterValidators } from '~shared/validators/parameters';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ParameterValue } from '~shared/shared.types';
import { ConstructionParameter } from '~shared/enums';

import { GenericItem } from '../cabinet-builder/engine-render.service';

interface ParameterMeta {
	label: string;
	parameterType: ConstructionParameter;
	inputType: string;
	level?: string;
}

@Component({
	selector: 'app-construction-parameter-input',
	templateUrl: './construction-parameter-input.component.html',
})
export class ConstructionParameterInputComponent implements OnInit, OnDestroy {
	@Input() name: string;
	@Input() parameters: ParameterMeta[] = [];
	@Input() selectedItem: Observable<IArticle>;
	@Input() infoImage?: string;
	@Input() infoText?: string;

	private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

	public availableParameters: (ParameterValue & ParameterMeta)[] = [];
	public panelType = null;
	public disabled = false;
	public show = true;
	public isOpen = true;
	public formGroup: FormGroup = new FormGroup({});
	public selectedParamLevel: string;

	constructor(
		private readonly itemsRepository: ItemsRepository,
		private readonly editorRepository: EditorRepository,
	) {}

	public ngOnInit(): void {
		this.initializeDefaults();

		this.editorRepository.selectedItems$
			.pipe(
				takeUntil(this.componentDestroyed$),
				map(([item]) => item),
				filter((item) => !!item),
			)
			.subscribe((item) => this.panelType = item.panelType);

		this.editorRepository.disableFields$
			.pipe(takeUntil(this.componentDestroyed$))
			.subscribe((disabled) => this.disabled = disabled);
	}

	@HostListener('document:keydown.escape')
	public onKeydownHandler() {
		this.initializeDefaults()
	}

	public submitForm(): void {
		if (!this.formGroup.valid && !this.formGroup.dirty) {
			return
		}

		this.editorRepository.setLoading(true);
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedItems$])
			.pipe(
				take(1),
				switchMap(([item, [panel]]) => {
					const availableParams = this.availableParameters;

					// Group available parameters by level
					const paramsByLevel = availableParams.reduce((acc, param) => {
						acc[param.level] = acc[param.level] || [];
						acc[param.level].push(param);
						return acc;
					}, {} as Record<string, typeof availableParams>);

					return forkJoin(
						Object.entries(paramsByLevel).map(([level, params]) => {
							const filteredParams = params
								.filter((param) => {
									return !!this.formGroup.value[param.parameterType] && !this.formGroup.get(param.parameterType).pristine})
								.map((param) => ({
									value: Number(this.formGroup.value[param.parameterType]),
									parameterType: param.parameterType
								}));

							if (level === 'panel' && filteredParams?.length) {
								return this.itemsRepository.updatePanelParameter(
									item.partId,
									item.id,
									panel.article?.id,
									panel.id,
									filteredParams
								);
							} else if (level === 'article' && filteredParams?.length) {
								return this.itemsRepository.updateArticleParameter(
									item.partId,
									item.id,
									panel.article?.id,
									filteredParams
								);
							} else {
								return of(item);
							}
						})
					).pipe(take(1), map(() => {
						this.selectedParamLevel = null;
						return panel;
					}))
				}),
				catchError((err) => {
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				}),
				switchMap((genericItem) => this.itemsRepository.activeItem$.pipe(take(1), map((item) => ({ item, panel: genericItem }))))
			)
			.subscribe(({ item, panel }) => {
				this.editorRepository.setLoading(false);

				const updatedPanel = findUpdatedPanel(item, panel);

				if (!updatedPanel) {
					return
				}

				this.editorRepository.setSelectedItems([{
					...updatedPanel,
					parameterSet: updatedPanel.parameterSet
				}])
			})
	}

	public toggleItem(): void {
		this.isOpen = !this.isOpen
	}

	public initializeDefaults(): void {
		this.selectedParamLevel = null;

		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedItems$])
			.pipe(
				takeUntil(this.componentDestroyed$),
			)
			.subscribe(([_, [item]]: [Item, GenericItem[]]) => {
				this.formGroup = new FormGroup({});

				const mappedParameters: (ParameterValue & ParameterMeta)[] = (this.parameters || [])
					.map((parameterMeta: ParameterMeta) => {
						const panelLevelParam: ParameterValue = item?.parameterSet?.parameters?.find(({ parameterType }) => parameterMeta.parameterType === parameterType);
						if (panelLevelParam) {
							return { ...panelLevelParam, ...parameterMeta, level: 'panel' }
						}
						const articleLevelParam = item?.article?.parameterSet?.parameters?.find(({ parameterType }) => parameterMeta.parameterType === parameterType);
						if (articleLevelParam) {

							return { ...articleLevelParam, ...parameterMeta, level: 'article' }
						}
					})
					.filter((param) => !!param);

				if (mappedParameters.length < 1) {
					return this.show = false;
				}

				this.show = true;
				this.availableParameters = mappedParameters.map((parameter: (ParameterValue & ParameterMeta)) => {
					this.formGroup.addControl(parameter.parameterType, new FormControl(parameter.value, [ParameterValidators.checkConstraints(parameter.constraint)]));

					this.formGroup.get(parameter.parameterType).enable();
					this.formGroup.get(parameter.parameterType).valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(value => {
						this.selectedParamLevel = parameter.level;
					});

					return parameter;
				});
				this.formGroup.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(values => {
					const isDefaultValues = this.availableParameters.filter(param => param.value === values[param.parameterType])
                    if (isDefaultValues.length === this.availableParameters.length) {
						this.selectedParamLevel = null;
					}
				});

			})
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}
}
