import { addBaseUrlToUrls, commonEnvironment } from "./environment.common";

export const environment = {
	...commonEnvironment,
	production: false,
	baseUrl: 'https://staging-api.kastenman.be',
	cognito: {
		REGION: 'eu-central-1',
		USER_POOL_ID: 'eu-central-1_U3hKpF0LJ',
		APP_CLIENT_ID: '587pjcnqlc63r8c41jopkduoq5',
		IDENTITY_POOL_ID: 'eu-central-1:be92e5d0-9bc0-42fb-80f7-9a76bbfa771a',
	}
};

addBaseUrlToUrls(environment, commonEnvironment);
