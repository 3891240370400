import { gsap } from 'gsap';
import {
	Group,
	MathUtils,
	Mesh,
	Object3D,
	PerspectiveCamera,
	Quaternion,
	Vector3,
} from 'three';

import { Engine } from './engine';

export class TweenManager {
	public tweens: Array<{
		openTween: gsap.core.Tween;
		closeTween: gsap.core.Tween;
		id: string;
	}> = [];

	constructor(private readonly renderService: Engine) {}


	rotateCamera(
		camera: PerspectiveCamera,
		toVal: Vector3,
	)
	{
		camera.rotation.set(toVal.x, toVal.y, toVal.z)
		this.renderService.controls.enabled = true;		//enable orbitControl again
		this.renderService.controls.update();
		this.renderService.needToRender();
	}
	//// Camera movement tween
	moveCameraOnAxis(
		camera: PerspectiveCamera,
		axis: 'x' | 'y' | 'z',
		toVal: number,
		duration: number
	) {
		camera.position[axis] = toVal;
		this.renderService.needToRender();
		// gsap.to(camera.position, {
		// 	[axis]: toVal,
		// 	duration: duration,
		// 	onUpdate: () => {
		// 		this.renderService.needToRender();
		// 	},
		// });
	}

	addMovementTween(obj: Object3D, duration: number, offsetValue: number){
		const offset = offsetValue
		const moveTween = gsap.fromTo(obj.position,
			{
				x: obj.position.x,
				y: obj.position.y,
				z: obj.position.z
			},
			{
				x: obj.position.x,
				y: obj.position.y,
				z: obj.position.z + offset,
				onUpdate: () => this.renderService.needToRender(60),
				paused: true,
				duration,
			}
		)
		return moveTween
	}
}
