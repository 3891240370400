import {
	AmbientLight,
	Color,
	Mesh,
	PerspectiveCamera,
	PMREMGenerator,
	Raycaster,
	Scene,
	WebGLRenderer,
	PlaneGeometry,
	MeshPhongMaterial,
	Fog,
	DirectionalLight,
	PCFSoftShadowMap
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import { Measurements } from './measurements';

const fov = 20;
export const cameraStartPos = { x: -5, y: 3, z: 7 };

export class Engine {
	public container: HTMLElement;
	public camera?: PerspectiveCamera;
	public renderer?: WebGLRenderer;
	public scene?: Scene;
	public controls?: OrbitControls;
	public enablePan: boolean;
	public renderTime: number;
	public animationFrameId: number;
	public raycaster?: Raycaster = new Raycaster();
	public width: number;
	public height: number;
	public measurementsManager?: Measurements;
	public dir_light: DirectionalLight;

	constructor(container: HTMLElement) {
		// First, try to dispose of existing scenes.
		while (container.firstChild) {
			container.removeChild(container.firstChild)
		}

		//// Scene set up
		this.container = container;
		const bounding = this.container.getBoundingClientRect();
		const { width, height } = bounding;
		this.camera = new PerspectiveCamera(fov, width / height, 0.1, 1000);
		this.camera.position.set(
			cameraStartPos.x,
			cameraStartPos.y,
			cameraStartPos.z
		);
		this.scene = new Scene();
		this.scene.background = new Color(0xFFFCDD);
		this.scene.fog = new Fog( 0xFFFCDD, 30, 100 );

		// ground
		const mesh = new Mesh( new PlaneGeometry( 200, 200 ), new MeshPhongMaterial( { color: 0xFFFFFF, depthWrite: false } ) );
		mesh.rotation.x = - Math.PI / 2;
		mesh.receiveShadow = true;
		this.scene.add( mesh );

		//// Renderer set up
		this.renderer = new WebGLRenderer({ antialias: true });
		this.renderer.shadowMap.enabled = true;
		this.renderer.shadowMap.type = PCFSoftShadowMap;
		this.renderer.autoClear = false;
		this.renderer.setPixelRatio(window.devicePixelRatio);
		this.renderer.setSize(width, height);
		//// Orbit controls set up
		this.controls = new OrbitControls(
			this.camera,
			this.renderer.domElement
		);
		this.controls.enablePan = this.enablePan;
		this.startControls();
	}

	resize() {
		if (!this.camera || !this.renderer) {
			return console.error('Camera or Renderer not defined');
		}

		const bounding = this.container.getBoundingClientRect();
		const width = bounding.width;
		const height = bounding.height;

		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
		this.renderer.setSize(width, height);
		this.needToRender();
	}

	dispose() {
		// dispose of all meshes
		this.scene?.traverse((object) => {
			if (!(object as Mesh).isMesh) {
				return;
			}

			// dispose geometry
			(object as Mesh).geometry.dispose();
			// @ts-ignore
			if (object.material.isMaterial) {
				this.cleanMaterial((object as Mesh).material);
			} else {
				// an array of materials
				// @ts-ignore
				for (const material of object.material) {
					this.cleanMaterial(material);
				}
			}
		});

		cancelAnimationFrame(this.animationFrameId);

		this.renderer?.domElement?.remove();
		delete this.renderer;
		delete this.camera;
		delete this.controls;
		delete this.raycaster;
	}

	initScene(): Promise<void> {
		console.log('Initializing scene...');
		if (!this.camera || !this.renderer || !this.scene) {
			const missingComponents = [];
			if (!this.camera) missingComponents.push('Camera');
			if (!this.renderer) missingComponents.push('Renderer');
			if (!this.scene) missingComponents.push('Scene');

			console.error(`Unable to initialize scene: Missing ${missingComponents.join(', ')}.`);
			return;
		}

		return new Promise<void>((resolve) => {
			const bounding = this.container.getBoundingClientRect();
			const width = bounding.width;
			const height = bounding.height;

			this.camera.aspect = width / height;
			this.camera.updateProjectionMatrix();

			this.renderer.setSize(width, height);
			this.renderer!.setSize(this.width, this.height);
			this.renderer!.render(this.scene!, this.camera!);
			this.container.appendChild(this.renderer!.domElement);

			console.log('add ambient light')
			const ambientLight = new AmbientLight(0xcccccc, 0.8);
			this.scene.add(ambientLight);

			this.dir_light = new DirectionalLight( 0xcccccc, 0.7);
			this.dir_light.castShadow = true;
			this.dir_light.position.set(-100, 300, 300)
			this.scene.add(this.dir_light);

			const pmremGenerator = new PMREMGenerator(this.renderer!);
			pmremGenerator.compileEquirectangularShader();

			requestAnimationFrame(() => {
				this.resize();
				resolve();
			});
		}).catch((e) => console.error('Error when initializing scene: ', e));
	}

	startControls() {
		if (!this.controls) return console.error('Controls not defined');
		this.controls.addEventListener('change', () => {
			this.needToRender();
		});
	}

	startRenderLoop() {
		if (!this.renderer) {
			return console.error('Renderer not defined')
		}

		this.animate();
	}

	needToRender(value: number = 2): void {
		if (this.renderTime > 2) {
			return;
		}

		this.renderTime = value;
	}

	private animate() {
		if (!this.camera || !this.renderer || !this.scene || !this.controls) {
			return console.error('Renderer, Camera, Scene or Controls not defined');
		}

		const animate = () => {
			this.animationFrameId = requestAnimationFrame(animate);

			if (this.renderTime >= 1) {
				if (this.renderTime > 1) {
					this.renderTime -= 1;
					this.controls?.update();
					this.renderer?.clearDepth();
					this.renderer?.render(this.scene, this.camera);

					if (this.measurementsManager) {
						this.measurementsManager.rotateTextToCamera(this.camera);
					}
				}
			}
		};

		animate();
	}

	cleanMaterial(material): void {
		// dispose material
		material.dispose();
		// dispose textures
		for (const key of Object.keys(material)) {
			const value = material[key];
			if (value && typeof value === 'object' && 'minFilter' in value) {
				value.dispose();
			}
		}
	}

	cleanScene(): void {
		if (!this.scene) {
			return;
		}

		while (this.scene.children.length > 0) {
			this.scene.remove(this.scene.children[0]);
		}
	}
}
