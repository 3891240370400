import { Injectable } from '@angular/core';
import { updateRequestStatus } from '@ngneat/elf-requests';
import { map, take, tap } from 'rxjs/operators';
import { deleteAllEntities, deleteEntities, resetActiveId, setActiveId, setEntities, updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { setProp, setProps } from '@ngneat/elf';
import { pick } from 'ramda';
import { Observable } from 'rxjs';

import { ArticleService } from '~core/services/article.service';
import { IArticle, Item, ItemPartCoordinate, IPanel } from '~shared/types';
import { CreateConnectionHardwareDTO, UpdateConnectionDTO, UpdateConnectionHardwareVariantDTO } from '~core/services/dto/article.dto';

import { editorStore } from '../editor/editor.store';
import { appStore } from '../../../../shared/store/app/app.store';

import { itemStore } from './items.store';
import { itemsSelector } from './items.selectors';
import { CreateItemDto, UpdateItemDimensionDto, UpsertDividerDto } from './items.types';

@Injectable()
export class ItemsRepository {
	public items$ = itemsSelector.items$;
	public itemsLoading$ = itemsSelector.itemsLoading$;
	public deletePanelLoading$ = itemsSelector.deletePanelLoading$;
	public deleteArticleLoading$ = itemsSelector.deleteArticleLoading$;
	public recreateBackLoading$ = itemsSelector.recreateBackLoading$;
	public updateItemDimensionLoading$ = itemsSelector.updateItemDimensionLoading$;
	public activeItem$ = itemsSelector.activeItem$;
	public partItems$ = itemsSelector.partItems$;

	constructor(private readonly articleService: ArticleService) {}

	public getItems(partIds: string[], noLoading = false): Observable<Item[]> {
		if (!noLoading) {
			itemStore.update(updateRequestStatus('items', 'pending'));
			appStore.update(setProp('loading', true));
		}

		itemStore.update(deleteAllEntities())
		return this.articleService
			.getItemsByPartIds(partIds)
			.pipe(
				take(1),
				tap((items) => {
					if (!noLoading) {
						appStore.update(setProp('loading', false));
					}
					itemStore.update(
						setEntities(items),
						updateRequestStatus('items', 'success')
					);
				})
			)
	}

	public getPartItems(partId: string): Observable<Item[]> {
		appStore.update(setProp('loading', false));
		return this.articleService
			.getItemsByPartIds([partId])
			.pipe(
				take(1),
				tap((items) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						setProp('partItems', items),
					)
				})
			)
	}

	public createItem(partId: string, item: CreateItemDto): Observable<Item> {
		itemStore.update(updateRequestStatus('items', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.createItem(partId, item)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('items', 'success')
					)
				})
			)
	}

	public deleteItem(partId: string, itemId: string): Observable<void> {
		itemStore.update(updateRequestStatus('items', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.deleteItem(partId, itemId)
			.pipe(
				take(1),
				tap(() => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						deleteEntities(itemId),
						updateRequestStatus('items', 'success')
					)
				})
			)
	}

	public duplicateItem(partId: string, itemId: string): Observable<Item> {
		itemStore.update(updateRequestStatus('create-divider', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.duplicateItem(partId, itemId)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('create-divider', 'success')
					)
				})
			)
	}

	public createDivider(partId: string, itemId: string, articleZoneId: string, body: UpsertDividerDto): Observable<IArticle[]> {
		itemStore.update(updateRequestStatus('create-divider', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.createDivider(partId, itemId, articleZoneId, body)
			.pipe(
				take(1),
				map((item) => {
					appStore.update(setProp('loading', false));
					const articles = this.findChangedArticles(itemStore.getValue().entities[itemStore.getValue().activeId], item);

					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('create-divider', 'success')
					)

					return articles;
				})
			)
	}

	public createArticleInArticleZone(partId: string, itemId: string, articleZoneId: string, articleType: string, body): Observable<Item> {
		appStore.update(setProp('loading', true));
		return this.articleService
			.createArticleInArticleZone(partId, itemId, articleZoneId, articleType, body)
			.pipe(
				take(1),
				map((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item)
					)

					return item;
				})
			)
	}

	public createShelf(partId: string, itemId: string, articleZoneId: string, body): Observable<IArticle[]> {
		itemStore.update(updateRequestStatus('create-shelf', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.createShelf(partId, itemId, articleZoneId, body)
			.pipe(
				take(1),
				map((item) => {
					appStore.update(setProp('loading', false));
					const articles = this.findChangedArticles(itemStore.getValue().entities[itemStore.getValue().activeId], item);

					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('create-shelf', 'success')
					)

					return articles;
				})
			)
	}

	public createDoor(partId: string, itemId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('create-door', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.createDoor(partId, itemId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('create-door', 'success')
					)
				})
			)
	}

	public createPanelCutout(partId: string, itemId: string, articleId: string, panelId: string, cutoutType: string): Observable<Item> {
		itemStore.update(updateRequestStatus('create-panel-cutout', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.createPanelCutout(partId, itemId, articleId, panelId, cutoutType)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('create-panel-cutout', 'success')
					)
				})
			)
	}

	public deletePanelCutout(partId: string, itemId: string, articleId: string, panelId: string, cutoutId: string): Observable<Item> {
		itemStore.update(updateRequestStatus('delete-panel-cutout', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.deletePanelCutout(partId, itemId, articleId, panelId, cutoutId)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('delete-panel-cutout', 'success')
					)
				})
			)
	}

	public updatePanelCutout(partId: string, itemId: string, articleId: string, panelId: string, cutoutId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-panel-cutout', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updatePanelCutout(partId, itemId, articleId, panelId, cutoutId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-panel-cutout', 'success')
					)
				})
			)
	}

	public updateHardware(partId: string, itemId: string, hardwareId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-panel-cutout', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateHardware(partId, itemId, hardwareId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-panel-cutout', 'success')
					)
				})
			)
	}

	public updateArticle(partId: string, itemId: string, articleId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-article', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateArticle(partId, itemId, articleId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-article', 'success')
					)
				})
			)
	}

	public updateConnectionHardwareVariant(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		connectionType: string,
		body: UpdateConnectionHardwareVariantDTO
	): Observable<Item> {
		appStore.update(setProp('loading', true));
		itemStore.update(updateRequestStatus('updateConnectionHardwareVariant', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateConnectionHardwareVariant(partId, itemId, articleId, panelId, connectionType, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('updateConnectionHardwareVariant', 'success')
					)
				})
			)
	}

	public updateConnection(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		connectionType: string,
		body: UpdateConnectionDTO
	): Observable<Item> {
		itemStore.update(updateRequestStatus('updateConnection', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateConnection(partId, itemId, articleId, panelId, connectionType, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('updateConnection', 'success')
					)
				})
			)
	}

	public createConnectionHardware(
		partId: string,
		itemId: string,
		articleId: string,
		panelId: string,
		connectionType: string,
		body: CreateConnectionHardwareDTO
	): Observable<Item> {
		itemStore.update(updateRequestStatus('createConnectionHardware', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.createConnectionHardware(partId, itemId, articleId, panelId, connectionType, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('createConnectionHardware', 'success')
					)
				})
			)
	}

	public updateDivider(partId: string, itemId: string, articleId: string, body: UpsertDividerDto): Observable<IArticle> {
		itemStore.update(updateRequestStatus('update-divider', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateDivider(partId, itemId, articleId, body)
			.pipe(
				take(1),
				map((item) => {
					const article = this.findArticle(articleId, item);
					appStore.update(setProp('loading', false));

					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-divider', 'success')
					)

					return article;
				})
			)
	}

	public updateShelf(partId: string, itemId: string, articleId: string, body): Observable<IArticle> {
		itemStore.update(updateRequestStatus('update-shelf', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateShelf(partId, itemId, articleId, body)
			.pipe(
				take(1),
				map((item) => {
					const article = this.findArticle(articleId, item);

					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-shelf', 'success')
					)

					return article;
				})
			)
	}

	public updatePanelParameter(partId: string, itemId: string, articleId: string, panelId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-panel-parameter', 'pending'));
		appStore.update(setProp('loading', true));

		return this.articleService
			.updatePanelParameter(partId, itemId, articleId, panelId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-panel-parameter', 'success')
					)
				})
			)
	}

	public updatePanel(partId: string, itemId: string, panelId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-panel', 'pending'));
		appStore.update(setProp('loading', true));

		return this.articleService
			.updatePanel(partId, itemId, panelId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-panel', 'success')
					)
				})
			)
	}

	public updateArticleParameter(partId: string, itemId: string, articleId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-article-parameter', 'pending'));
		appStore.update(setProp('loading', true));

		return this.articleService
			.updateArticleParameter(partId, itemId, articleId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-article-parameter', 'success')
					)
				})
			)
	}

	public updateFillerConstruction(partId: string, itemId: string, outlineFaceIdx: number, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-filler-construction', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateFillerConstruction(partId, itemId, outlineFaceIdx, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-filler-construction', 'success')
					)
				})
			)
	}

	public deleteArticle(partId: string, itemId: string, articleId: string): Observable<Item> {
		itemStore.update(updateRequestStatus('delete-article', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.deleteArticle(partId, itemId, articleId)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('delete-article', 'success')
					)
				})
			)
	}

	public updateItemDimension(itemId: string, partId: string, dimensions: UpdateItemDimensionDto): Observable<Item> {
		itemStore.update(updateRequestStatus('update-item-dimension', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateItemDimension(itemId, partId, dimensions)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-item-dimension', 'success')
					)
				})
			)
	}

	public getItem(itemId: string, partId: string): Observable<Item> {
		itemStore.update(updateRequestStatus('get-item', 'pending'));
		appStore.update(setProp('loading', true));

		return this.articleService.getItem(itemId, partId).pipe(
			take(1),
			tap((item) => {
				appStore.update(setProp('loading', false));
				itemStore.update(
					setEntities([item]),
					updateRequestStatus('get-item', 'success')
				)
			}));
	}


	public updateItem(itemId: string, partId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-item', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateItem(itemId, partId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-item', 'success')
					)
				})
			)
	}

	public updateItemName(itemId: string, partId: string, name): Observable<Item> {
		itemStore.update(updateRequestStatus('update-item', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateItemName(itemId, partId, name)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-item', 'success')
					)
				})
			)
	}

	public updateItemCoordinates(itemId: string, partId: string, coordinates: ItemPartCoordinate): Observable<Item> {
		itemStore.update(updateRequestStatus('update-item-coordinates', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updateItemCoordinates(itemId, partId, coordinates)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						// upsertEntities(item),
						updateRequestStatus('update-item-coordinates', 'success')
					)
				})
			)
	}

	public addItemFiller(itemId: string, partId: string, fillerType: string, baseType: string, outlineFaceIdx: number): Observable<Item> {
		itemStore.update(updateRequestStatus('add-item-filler', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.addItemFiller(itemId, partId, fillerType, baseType, outlineFaceIdx)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						updateEntities(item.id, item),
						updateRequestStatus('add-item-filler', 'success')
					)
				})
			)
	}

	public removeItemFiller(itemId: string, partId: string, outlineFaceIdx: number): Observable<Item> {
		itemStore.update(updateRequestStatus('add-item-filler', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.removeItemFiller(itemId, partId, outlineFaceIdx)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						updateEntities(itemId, item),
						updateRequestStatus('remove-item-filler', 'success')
					)
				})
			)
	}

	public updatePanelGroupBoard(partId: string, itemId: string, panelGroup: string, body, panelLabel?: string): Observable<Item> {
		itemStore.update(updateRequestStatus('update-panel-group-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updatePanelGroupBoard(partId, itemId, panelGroup, body, panelLabel)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-panel-group-board', 'success')
					)
				})
			)
	}

	public recreateBack(partId: string, itemId: string, articleZoneId: string, faceIdx: number): Observable<Item> {
		itemStore.update(updateRequestStatus('recreate-back', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.recreateBack(partId, itemId, articleZoneId, faceIdx)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('recreate-back', 'success')
					)
				})
			)
	}

	public updatePartPanelGroupBoard(partId: string, panelGroup: string, body, panelLabel?: string): Observable<Item[]> {
		itemStore.update(updateRequestStatus('update-panel-group-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updatePartPanelGroupBoard(partId, panelGroup, body, panelLabel)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-panel-group-board', 'success')
					)
				})
			)
	}

	public updatePanelBoard(partId: string, itemId: string, articleId: string | undefined, panelId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-panel-board', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updatePanelBoard(partId, itemId, articleId, panelId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						// updateEntities(itemId, (item) => ({
						// 	...item,
						// 	frontArticle: {
						// 		...item.frontArticle,
						// 		panels: item.frontArticle.panels.map((frontPanel) => {
						// 			if (frontPanel.id !== panelId) {
						// 				return frontPanel;
						// 			}

						// 			return {
						// 				...frontPanel,
						// 				board: updatedPanel.board,
						// 			}
						// 		})
						// 	},
						// 	articles: item.articles.map((article) => {
						// 		if (article.id !== articleId) {
						// 			return article;
						// 		}

						// 		return {
						// 			...article,
						// 			panels: article.panels.map((panel) => {
						// 				if (panel.id !== panelId) {
						// 					return panel;
						// 				}

						// 				return ({
						// 					...panel,
						// 					board: updatedPanel.board,
						// 				});
						// 			})
						// 		}
						// 	})
						// })),
						upsertEntities(item),
						updateRequestStatus('update-panel-board', 'success')
					)
				})
			)
	}

	public deletePanel(partId: string, itemId: string, panelId: string): Observable<Item> {
		itemStore.update(updateRequestStatus('delete-panel', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.deletePanel(partId, itemId, panelId)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('delete-panel', 'success')
					)
				})
			)
	}

	public updatePanelConnection(partId: string, itemId: string, articleId: string, panelId: string, connectionType: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('update-panel-cutout', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.updatePanelConnection(partId, itemId, articleId, panelId, connectionType, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('update-panel-cutout', 'success')
					)
				})
			)
	}

	public deleteHardwareFromPanel(partId: string, itemId: string, articleId: string, panelId: string): Observable<Item> {
		itemStore.update(updateRequestStatus('delete-hardware-from-panel', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.deleteHardwareFromPanel(partId, itemId, articleId, panelId)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('delete-hardware-from-panel', 'success')
					)
				})
			)
	}

	public addHardwareToPanel(partId: string, itemId: string, articleId: string, panelId: string, body): Observable<Item> {
		itemStore.update(updateRequestStatus('add-hardware-to-panel', 'pending'));
		appStore.update(setProp('loading', true));
		return this.articleService
			.addHardwareToPanel(partId, itemId, articleId, panelId, body)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
						updateRequestStatus('add-hardware-to-panel', 'success')
					)
				})
			)
	}

	public addCustomisationToPanel(partId: string, itemId: string, panelId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp('loading', true));
		return this.articleService
			.addCustomisationToPanel(partId, itemId, panelId, customisationId)
			.pipe(take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
					)
				})
			)
	}

	public addCustomisationToLayout(partId: string, itemId: string, articleZoneId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp('loading', true));
		return this.articleService
			.addCustomisationToLayout(partId, itemId, articleZoneId, customisationId)
			.pipe(take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
					)
				})
			)
	}

	public addCustomisationToItem(partId: string, itemId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp('loading', true));
		return this.articleService
			.addCustomisationToItem(partId, itemId, customisationId)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
					)
				})
			)
	}

	public deleteCustomisationFromPanel(partId: string, itemId: string, panelId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp('loading', true));
		return this.articleService
			.deleteCustomisationFromPanel(partId, itemId, panelId, customisationId)
			.pipe(take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
					)
				})
			)
	}

	public deleteCustomisationFromLayout(partId: string, itemId: string, articleZoneId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp('loading', true));
		return this.articleService
			.deleteCustomisationFromLayout(partId, itemId, articleZoneId, customisationId)
			.pipe(take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
					)
				})
			)
	}

	public deleteCustomisationFromItem(partId: string, itemId: string, customisationId: string): Observable<Item> {
		appStore.update(setProp('loading', true));
		return this.articleService
			.deleteCustomisationFromItem(partId, itemId, customisationId)
			.pipe(
				take(1),
				tap((item) => {
					appStore.update(setProp('loading', false));
					itemStore.update(
						upsertEntities(item),
					)
				})
			)
	}

	public createTempNewItem(item: Partial<Item>): void {
		itemStore.update(
			setActiveId(item.id),
			upsertEntities(item),
		);
	}

	public saveTempNewItem(values): void {
		appStore.update(setProp('loading', true));
		const tempItem = itemStore.getValue().entities['new'];

		this.articleService
			.createItem(tempItem.partId, {
				dimension: values.dimension,
				outlineSetLabel: values.outlineSetLabel,
				...pick(['itemType', 'name'])(tempItem)
			})
			.pipe(take(1))
			.subscribe((item) =>{
				editorStore.update(setProps({
					dimensions: null
				}));

				appStore.update(setProp('loading', false));
				itemStore.update(
					deleteEntities('new'),
					upsertEntities(item),
					setActiveId(item.id),
					updateRequestStatus('items', 'success')
				)
			});
	}

	public activateItem(itemId: string): void {
		itemStore.update(
			setActiveId(itemId),
		);
	}

	public clearItems(): void {
		itemStore.update(
			resetActiveId(),
			deleteAllEntities()
		);
	}

	private findArticle(oldArticleId: string, newItem: Item): IArticle {
		return newItem.articles
			.find((article) => article.id === oldArticleId);
	}

	private findChangedArticles(oldItem: Item, newItem: Item): IArticle[] {
		return newItem.articles
			.filter((article) => !oldItem.articles
				.find((oldArticle) => oldArticle.id === article.id));
	}
}
