import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, Scroll} from '@angular/router';
import { combineLatest, filter, Observable, startWith, tap, debounceTime } from 'rxjs';

import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { AppRepository } from '~shared/store';

import { AuthService } from '../../services/auth.service';
import { ExternalUser, InternalUser, UserType } from '../../../modules/auth/types/user.types';
import {ProjectsRepository} from "~modules/projects/store/projects/projects.repository";
import {Project} from "~shared/types";


@Component({
	templateUrl: './wrapper.component.html',
})
export class WrapperComponent implements OnInit {
	public loading$: Observable<boolean>;
	public loading: boolean;
	public menuItems = [];

	public disableNavigation = false;

	constructor(
		private readonly authService: AuthService,
		private readonly editorRepository: EditorRepository,
		private readonly appRepository: AppRepository,
		private readonly projectsRepository: ProjectsRepository,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
	) {}

	async ngOnInit(): Promise<void> {
		this.authService.loading$
			.subscribe((loading) => this.editorRepository.setLoading(loading));

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd)
			)
			.subscribe(() => {
				this.disableNavigation = !!this.route.snapshot.firstChild?.data?.disableNavigation;
			});


		this.loading$ = this.appRepository.loading$;

		this.loading$
			.pipe(
				filter((isLoading) => !!isLoading),
				debounceTime(10_000),
				tap(() => this.appRepository.setLoading(false))
			)
			.subscribe()

		combineLatest([
			this.authService.currentUser$,
			this.projectsRepository.projects$,
			this.router.events.pipe(
				startWith(null),
				filter((router) => !router || router instanceof Scroll)
			)
		])
			.pipe(
				tap(([user, projects, event]: [InternalUser | ExternalUser, Project[], Scroll]) => {
					const isTermsPage = this.router.url.includes('algemene_voorwaarden');

					if (!user || !user.registered || isTermsPage) {
						return;
					}

					if (user?.userType === UserType.EXTERNAL) {
						const lastVisitedProjectName = localStorage.getItem('lastVisitedProject') && projects?.length ?
							projects?.find((project: Project) =>
							project.id === localStorage.getItem('lastVisitedProject'))?.name : '';

						return this.menuItems = [
							{
								icon: 'home',
								to: '/projects'
							},
							!lastVisitedProjectName || event?.routerEvent?.url?.includes('editor') ? {
								icon: 'configurator',
								to: '/projects/create',
								appTooltip: 'Start nieuw project',
								queryParams: {
									autoCreate: true
								}
							} : {
								icon: 'configurator',
								appTooltip: `Terug naar project ${lastVisitedProjectName}`,
								to: `/projects/${localStorage.getItem('lastVisitedProject')}/editor`,
							},
							{
								icon: 'wood',
								to: '/materials',
							}
						]
					}

					this.menuItems = [
						{
							icon: 'home',
							to: '/projects'
						},
						{
							icon: 'wood',
							to: '/materials',
						},
					]
				}))
			.subscribe();
	}
}
