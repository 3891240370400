export enum BoardType {
	RAW = 'RAW',
	FINISHED = 'FINISHED',
	COMPOSED = 'COMPOSED',
	FAVOURITED = 'FAVOURITED',
}

export enum BoardColour {
	WHITE = 'WHITE',
	BLACK = 'BLACK',
	GREY = 'GESIBOIS',
	BROWN = 'BROWN',
	DARK_BROWN = 'DARK_BROWN',
	BEIGE = 'BEIGE',
	OTHER = 'OTHER'
}

export enum RawBoardType {
	PARTICLEBOARD = 'PARTICLEBOARD',
	MDF = 'MDF',
	MDFWR = 'MDFWR',
	MDFBLACK = 'MDFBLACK',
	PLYWOOD = 'PLYWOOD',
	BLOCK = 'BLOCK',
	OSB = 'OSB',
	SOLIDCORE = 'SOLIDCORE',
}

export enum RawBoardAspect {
	WATERRESISTANT = 'WATERRESISTANT',
	POPLAR = 'POPLAR',
	BIRCH = 'BIRCH',
	HIGHDENSITY = 'HIGHDENSITY',
	REGULARDENSITY = 'REGULARDENSITY',
}

export enum BoardOverlayCategory {
	UNICOLOUR = 'UNICOLOUR',
	STONE = 'STONE',
	PATTERN = 'PATTERN',
	WOOD = 'WOOD'
}

export enum BoardOverlayType {
	VENEER = "VENEER",
	LAMINATE = "LAMINATE",
	CPLAMINATE = "CPLAMINATE",
	ACRYLIC = "ACRYLIC",
	MELAMINE = "MELAMINE",
	PRIMER = "PRIMER",
	BACKING = "BACKING",
	VARNISH = "VARNISH",
	SOLID = "SOLID",
	POWDER_COATING = "POWDER_COATING"
}

export enum BoardOverlayAspect {
	MATT = 'MATT',
	REGULAR = 'REGULAR',
	GLOSSY = 'GLOSSY',
	SATIN_BRUSHED = 'SATIN_BRUSHED'
}

export enum BoardGlueType {
	LASER = 'LASER',
	PU = 'PU',
	EVA = 'EVA'
}

export enum EdgebandType {
	ABS = 'ABS',
	LASER = 'LASER',
	VENEER = 'VENEER',
	PAPER = 'PAPER'
}
