import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validator, Validators} from '@angular/forms';
import {
	catchError,
	combineLatest,
	filter,
	Subject,
	switchMap,
	take,
	takeUntil,
	throwError,
	Observable,
	map,
	tap,
	of,
	BehaviorSubject,
} from 'rxjs';

import { FrontConnection } from '~shared/enums/item.enum';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { ItemsRepository } from '~modules/projects/store/items/items.repository';
import { GenericItem } from '~shared/components/cabinet-builder/engine-render.service';
import { Item } from '~shared/types';
import { HardwareType } from '~shared/enums';
import { AppRepository } from '~shared/store';
import { EditorMode } from '~modules/projects/store/editor/editor.types';


@Component({
	templateUrl: './door.component.html',
})
export class DoorComponent implements OnInit, OnDestroy {
	public configurationForm: FormGroup<{
		id: FormControl<string>;
		frontConnection: FormControl<FrontConnection>;
	}>;
	public openItems: Record<string, boolean> = {
		GENERAL: true,
		HINGE_TYPE: true,
		HINGE_COLOUR: true,
	};
	public activePanel$: Observable<GenericItem>;
	public selectedItems$: Observable<GenericItem[]>;
	public activeItem$: Observable<Item>;
	public loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public hingeTypeControl = new FormControl();
	public hingeColourControl = new FormControl();
	public hingeColourOptions = [];

	private componentDestroyed$: Subject<boolean> = new Subject()

	constructor(
		private readonly editorRepository: EditorRepository,
		private readonly itemsRepository: ItemsRepository,
		private readonly appRepository: AppRepository,
		private readonly fb: FormBuilder
	) {}

	@HostListener('document:keydown.escape', ['$event'])
	public onKeydownHandler(event: KeyboardEvent) {
		this.initializeDefaults();
	}

	public ngOnInit(): void {
		this.configurationForm = this.fb.group({
			id: ['new'],
			frontConnection: [null],
		});

		this.activeItem$ = this.itemsRepository.activeItem$;
		this.selectedItems$ = this.editorRepository.selectedItems$;
		this.activePanel$ = this.editorRepository.selectedItems$
			.pipe(map(([item]) => item));

		// this.configurationForm.get('frontConnection').valueChanges
		// 	.pipe(takeUntil(this.componentDestroyed$))
		// 	.subscribe(() => setTimeout(() => this.createItem()));

		this.hingeTypeControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((value) => combineLatest([this.activePanel$, this.activeItem$]).pipe(take(1), map(([panel, item]) => ({ panel, value, item })))),
				switchMap(({ panel, value, item }) => {
					return this.itemsRepository.updateConnection(item.partId, item.id, panel.article?.id, panel.id, 'HINGE', {
						hingeType: value,
						connectionType: 'HINGE'
					})
				}),
				take(1),
			)
			.subscribe()

		this.hingeColourControl.valueChanges
			.pipe(
				takeUntil(this.componentDestroyed$),
				switchMap((value) => combineLatest([this.activePanel$, this.activeItem$]).pipe(take(1), map(([panel, item]) => ({ panel, value, item })))),
				switchMap(({ panel, value, item }) => this.itemsRepository.updateConnectionHardwareVariant(item.partId, item.id, panel.article?.id, panel.id, 'HINGE', {
					hardwareType: HardwareType.HINGE,
					label: (panel.hingeConnection as any)?.connectors[0]?.variant?.sku,
					sku: (panel.hingeConnection as any)?.connectors[0]?.variant?.sku,
					colour: value
				})),
				take(1),
			)
			.subscribe()

		this.initializeDefaults();
	}

	public submitForm(): void {
		const { value } = this.configurationForm;

		if (value.id === 'new' && value.frontConnection) {
			return this.createItem();
		}
	}

	public createItem(): void {
		const { value } = this.configurationForm;

		this.editorRepository.setLoading(true);
		this.loading$.next(true);
		combineLatest([this.itemsRepository.activeItem$, this.editorRepository.selectedItems$])
			.pipe(
				take(1),
				switchMap(([item, articleZones]) => this.itemsRepository.createDoor(item.partId, item.id, {
					frontConnection: value.frontConnection,
					articleZoneIds: articleZones.map((articleZone) => articleZone.id)
				})),
				catchError((err) => {
					this.loading$.next(false);
					this.editorRepository.setLoading(false)
					return throwError(() => err);
				})
			)
			.subscribe(() => {
				this.loading$.next(false);
				this.editorRepository.setLoading(false);
				this.editorRepository.setActiveConfigurationPanel(null);
				this.editorRepository.setSelectedItems([]);
				this.editorRepository.setOpenDoorsIds([]);
				this.editorRepository.setMode(EditorMode.DEFAULT)
			})
	}

	public toggleItem(itemName: string): void {
		this.openItems[itemName] = !this.openItems[itemName] || false;
	}

	public ngOnDestroy(): void {
		this.componentDestroyed$.next(true);
		this.componentDestroyed$.complete();
	}

	public initializeDefaults(): void {
		this.editorRepository.selectedDoors$
			.pipe(
				takeUntil(this.componentDestroyed$),
				filter((doors) => !!doors && !!doors.length)
			)
			.subscribe(([door]) => {
				this.configurationForm.reset({}, { emitEvent: false });
				this.configurationForm.patchValue({
					id: door.id,
					frontConnection: door.frontConnection
				}, { emitEvent: false });

				if (!door.hingeConnection) {
					return;
				}

				const hinge = door.hingeConnection;
				const hardware = hinge.hardware?.[0];
				this.hingeColourOptions = (hardware?.variantList || [])
					.filter((item, index, self) =>
						index === self.findIndex((t) => (
							t.colour === item.colour
						))
					)
					.map((variant) => ({
						value: variant.colour,
						// label: `${variant.colour}${variant.spring ? ` (met veer)` : ''}`,
						label: `${variant.colour}`,
					}))

				this.hingeTypeControl.setValue(hinge.hingeType, { emitEvent: false })
				this.hingeColourControl.setValue(hardware?.style, { emitEvent: false })
			})
	}
}
