import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { DeliverableAddressType } from '~modules/auth/types/account.types';
import { UserType } from '~modules/auth/types/user.types';
import { CustomValidators } from '~shared/validators/custom';

const sharedUserField = {
	firstname: [null, [Validators.required]],
	lastname: [null, [Validators.required]],
	telephonenumber: [null, [Validators.required, CustomValidators.validPhoneNumber]],
};

const addressFields = {
	street: [null, [Validators.required]],
	busNumber: [null, []],
	houseNumber: [null, [Validators.required]],
	zip: [null, [Validators.required]],
	city: [null, [Validators.required]],
	country: [null, [Validators.required]]
};

export const buildIndividualAccountForm = (
	fb: UntypedFormBuilder,
	deliveryAddressType: DeliverableAddressType,
	userType: UserType
): UntypedFormGroup => fb.group({
	firstname: [null, [Validators.required]],
	lastname: [null, [Validators.required]],
	invoiceAddress: fb.group(addressFields),
	...(deliveryAddressType === DeliverableAddressType.OTHER && {
		deliveryDetails: fb.group({
			...addressFields,
			...sharedUserField,
		})
	}),
	generalEmail: [null, [Validators.required]],
});

export const buildProfessionalAccountForm = (
	fb: UntypedFormBuilder,
	deliveryAddressType: DeliverableAddressType
): UntypedFormGroup => fb.group({
	firstname: [null, [Validators.required]],
	lastname: [null, [Validators.required]],
	domain: [null, [Validators.required]],
	...(deliveryAddressType === DeliverableAddressType.OTHER && {
		deliveryDetails: fb.group({
			...addressFields,
			...sharedUserField,
		})
	}),
	company: fb.group({
		name: [null, [Validators.required]],
		companyClass: [null, [Validators.required]],
		vatNumber: [null, [Validators.required]],
	}, []),
	invoiceAddress: fb.group(addressFields),
	generalEmail: [null, [Validators.required]],
	invoiceEmail: [null, [Validators.required]],
});
