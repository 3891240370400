import { take, tap, timer } from 'rxjs';
import {
	Group,
	Box3,
	Vector3,
	Mesh,
	BufferGeometry,
	Color,
	Vector2,
	Plane,
	Object3D,
	Material,
} from 'three';

import { PanelType } from '~shared/enums';
import { EditorRepository } from '~modules/projects/store/editor/editor.repository';
import { EditorMode } from '~modules/projects/store/editor/editor.types';
import { SelectionType } from '~shared/shared.types';
import { EDITOR_STATE_COLOURS } from '~shared/shared.const';

import {
	IArticle,
	IArticleZone,
	Item,
} from '../../../shared/types';

import { ShapeInfo, GenericItem } from './engine-render.service';
import { HelperFunctions } from './helperFunctions';
import { Loader } from './loader';
import { CutoutShapes } from './cutoutShapes';
import { MovementManager } from './movementManager';
import { ObjectBuilder } from './objectBuilder';
import { cameraStartPos, Engine } from './engine';
import { Measurements } from './measurements';
import { SelectionManager } from './selectionManager';

interface IBuildItemProps {
	item: Item;
	transparentPanelTypes: PanelType[];
	isolatedItem: GenericItem;
	openDoorIds?: string[];
	hideDoors?: boolean;
	editorMode: EditorMode;
	renderView?: string;
}

export class ItemEditor {
	public groups: Group[] = [];
	public currentObjectId: string;
	public isClickAndHold = false;
	public lastOpenDoors: string[] = [];
	public lastSelectedObj: Mesh;
	public subscription;
	public loader: Loader = new Loader();
	public cutoutGenerator: CutoutShapes = new CutoutShapes();
	public movableObjects = [];
	public mouseVector: Vector2 = new Vector2();
	public movementManager = new MovementManager(this.mouseVector, this.engine);
	public isPointerdown: boolean = false;
	public activeCutout?: Mesh;
	public transparentPanelTypes: PanelType[];
	public isolatedItem: GenericItem;
	public wireframeFloor: Mesh;
	public editorMode: EditorMode;

	private plane = new Plane();
	private pNormal = {
		x: new Vector3(1, 0, 0),
		y: new Vector3(0, 1, 0),
		z: new Vector3(0, 0, 1),
	}; // plane's normal
	private planeIntersect = new Vector3(); // point of intersection with the plane
	private pIntersect = new Vector3(); // point of intersection with an object (plane's point)
	private shift = new Vector3(); // distance between position of an object and points of intersection with the object

	constructor(
		private readonly objectBuilder: ObjectBuilder,
		private readonly engine: Engine,
		private readonly measurementsManager: Measurements,
		private readonly selectionManager: SelectionManager,
		private readonly editorRepository: EditorRepository,
	) {
		this.editorRepository.mode$
			.pipe(tap((mode) => this.editorMode = mode))
			.subscribe();
	}

	build({ item, transparentPanelTypes, isolatedItem, openDoorIds = [], hideDoors = false, editorMode, renderView }: IBuildItemProps): void {
		this.transparentPanelTypes = transparentPanelTypes;
		this.isolatedItem = isolatedItem;
		const cabinet = this.objectBuilder.buildCabinet(item, transparentPanelTypes, isolatedItem, editorMode);
		const [panelGroup] = cabinet;

		//// Panel groups
		this.engine.scene.add(panelGroup as Group);
		this.groups.push(panelGroup as Group);

		//// Filler groups
		for (const panelGroup of cabinet[3] as Group[]) {
			this.engine.scene.add(panelGroup);
			this.groups.push(panelGroup);
		}

		for (const baseGroup of cabinet[1] as Group[]) {
			this.engine.scene.add(baseGroup);
			this.groups.push(baseGroup);
		}

		//// ArticleZone group[]
		this.engine.scene.add(cabinet[2] as Group);
		this.groups.push(cabinet[2] as Group);

		if (this.currentObjectId !== item.id) {
			this.currentObjectId = item.id;
			const aabb = new Box3().setFromObject(cabinet[2] as Group);
			const center = aabb.getCenter(new Vector3());
			this.engine.controls.target = center;
			if (renderView === 'default' || !renderView) {
				this.engine.camera.position.set(cameraStartPos.x, cameraStartPos.y, cameraStartPos.z);
			} else if (renderView === 'front') {
				this.engine.camera.position.set(center.x, center.y, 8);
			}
		}

		const aabb = new Box3().setFromObject(panelGroup as Group);
		const center = aabb.getCenter(new Vector3());
		this.engine.controls.target = center;
		const size = new Vector3();
		aabb.getSize(size);



		(cabinet[0] as Group).children.forEach((group) => {
			this.addDoorTweens(group as Group, openDoorIds);
		})
		this.engine.startRenderLoop();

		if (hideDoors) {
			this.triggerDoors(true, [], true)
		}

		this.editorRepository.selectedItems$
			.pipe(take(1))
			.subscribe((items) => {
				this.highlightObjects(items, this.isolatedItem);
			});

	}

	disposeCurrentModel(): void {
		this.removeMeasurements();

		for (let i = this.groups.length - 1; i >= 0; i--) {
			this.groups[i].traverse((object) => {
				if (!(object as Mesh).isMesh) {
					return;
				}
				// dispose geometry
				(object as Mesh).geometry.dispose();
				// @ts-ignore
				if (object.material.isMaterial) {
					HelperFunctions.cleanMaterial((object as Mesh).material);
				} else {
					// an array of materials
					// @ts-ignore
					for (const material of object.material) {
						this.engine.cleanMaterial(material);
					}
				}
				this.engine.scene.remove(object);
			});
			this.engine.scene.remove(this.groups[i]);
		}
	}

	// for displaying measurements
	/**
	 * @param boundingBox - bounding box of the object you want to add measurements
	 * @param x - optional - give the value you need to display on the x axis, or empty string i.e. "" to not show measurement line
	 * @param y - optional - give the value you need to display on the y axis, or empty string i.e. "" to not show measurement line
	 * @param z - optional - give the value you need to display on the y axis, or empty string i.e. "" to not show measurement line
	 */
	showSingleArticleZoneMeasurement(
		boundingBox: Box3,
		x?: string,
		y?: string,
		z?: string
	) {
		this.measurementsManager.articleZoneMeasurements(boundingBox, x, y, z);
	}

	showSinglePanelMeasurement(
		boundingBox: Box3,
		x?: string,
		y?: string,
		z?: string
	) {
		this.measurementsManager.panelMeasurements(boundingBox, x, y, z);
	}

	// used for disposing of all measurements
	removeMeasurements() {
		if (!this.engine.renderer) {
			return;
		}

		this.engine.renderer.sortObjects = true;
		this.measurementsManager.dispose();
	}

	//// Pass the group you want to measure
	groupMeasurements(group: Group) {
		const geometryArr: BufferGeometry[] = [];
		for (const mesh of group.children) {
			if (mesh.children.length > 0) {
				geometryArr.push((mesh.children[0] as Mesh).geometry);
			} else {
				geometryArr.push((mesh as Mesh).geometry);
			}
		}
		const geometryFinal: BufferGeometry | null =
			HelperFunctions.mergeBufferGeometries(geometryArr);
		if (!geometryFinal)
			return console.error("Group measurements couldn't be computed");
		geometryFinal.computeBoundingBox();
		return geometryFinal;
	}

	addDoorTweens(group: Group, openDoorIds: string[]) {
		for (let i = group.children.length - 1; i >= 0; i--) {
			const mesh = group.children[i];
			if (openDoorIds.includes(mesh.userData.object.id)) {
				mesh.userData.disallowSelection = true;
				mesh.visible = false;

				// const objectDefaultMaterial = mesh.userData.defaultMaterial;
				// const transparentMaterial = objectDefaultMaterial.clone();
				// transparentMaterial.opacity = 0.2;
				// transparentMaterial.transparent = true;
				// HelperFunctions.applyTemporaryMaterial([mesh as Mesh, ...(mesh.children as Mesh[])], transparentMaterial)
			}
		}
	}

	triggerDoors(open: boolean, doorIds: string[], openAllDoors: boolean = false) {
		const allDoors = this.findDoors(this.engine.scene.children);

		if (openAllDoors) {
			return allDoors.forEach((door) => {
				door.userData.disallowSelection = true;
				door.visible = false;
				this.engine.needToRender(50);
			})
		}

		allDoors.forEach((door) => {
			const shouldBeOpen = doorIds.includes(door.userData.object.id);
			const wasOpen = this.lastOpenDoors.includes(door.userData.object.id);

			// If the door should be open but it was open, ignore
			if (shouldBeOpen === wasOpen) {
				return;
			}

			const objectDefaultMaterial = door.userData.defaultMaterial as Material;
			// If the door should be open but it was closed, open it
			if (shouldBeOpen && !wasOpen) {
				// Disallow selection
				door.userData.disallowSelection = true;
				door.visible = false;

				// const transparentMaterial = objectDefaultMaterial.clone();
				// transparentMaterial.opacity = 0.2;
				// transparentMaterial.transparent = true;
				// HelperFunctions.applyTemporaryMaterial([door, ...(door.children as Mesh[])], transparentMaterial);
				this.engine.needToRender(50);
				return;
			}

			// If the door should be closed but it was open, close it
			if (!shouldBeOpen && wasOpen) {
				door.visible = true;
				door.userData.disallowSelection = false;

				// HelperFunctions.restoreDefaultMaterial([door, ...(door.children as Mesh[])]);
				this.engine.needToRender(50);
				return;
			}
		});

		this.lastOpenDoors = doorIds;
	}

	startTimer() {
		const source = timer(300);
		this.subscription = source.subscribe(() => {
			this.isClickAndHold = true;
		});
	}

	endTimer() {
		this.subscription.unsubscribe();
	}

	selectObject(
		mouseX: number,
		mouseY: number,
		isCtrlDown: boolean
	): {
		primaryObjects: ShapeInfo[];
		allObjects: ShapeInfo[];
	} {
		if (this.isClickAndHold) {
			this.isClickAndHold = false;
			//@ts-ignore
			return;
		}

		const selection = this.selectionManager.selectObject(
			mouseX,
			mouseY,
			this.engine.container,
			this.isolatedItem,
		);
		const selectionType = SelectionManager.getSelectionType(
			(selection[0] as Mesh)?.userData?.object as GenericItem
		);

		const isSelectionOfDifferentType =
			!this.selectionManager.selectedPrimaryObjects.find((obj) => {
				return (
					SelectionManager.getSelectionType(obj.object) ===
					SelectionManager.getSelectionType(
						(selection[0] as Mesh)?.userData?.object as GenericItem
					)
				);
			});

		if (this.lastSelectedObj && !isCtrlDown) {
			this.selectionManager.deselectAll(this.transparentPanelTypes, this.isolatedItem);
		}

		if (this.lastSelectedObj && isCtrlDown && isSelectionOfDifferentType) {
			this.selectionManager.deselectAll(this.transparentPanelTypes, this.isolatedItem);
		}

		if ((selection[0] && this.editorMode === EditorMode.DEFAULT) || (selectionType === SelectionType.ARTICLE_ZONE && this.editorMode === EditorMode.SELECT_ARTICLE_ZONES)) {
			this.lastSelectedObj = selection[0] as Mesh;
			this.selectionManager.handleObjectSelection(this.lastSelectedObj);
		}

		if (selection[0] && this.checkCutout(selection[0])) {
			this.selectCutout(true, selection[0] as Mesh);
		} else {
			this.selectCutout(false);
		}

		this.engine.needToRender(60);
		this.isClickAndHold = false;

		return {
			primaryObjects: this.selectionManager.selectedPrimaryObjects,
			allObjects: this.selectionManager.getSelectedShapesInfo(),
		};
	}

	highlightObjects(items: GenericItem[], isolatedItem: GenericItem): void {
		//@ts-ignore
		const cleanedItems: GenericItem[] = items.reduce((acc, item) => {
			if (!item) {
				return acc;
			}

			return [...acc, item];
		}, []);

		//@ts-ignore
		if (cleanedItems.length == 0) {
			return;
		}

		//@ts-ignore
		cleanedItems.forEach(({ article, id, articleZone, mesh: originalMesh, type, faceNormal }) => {
			let mesh: Mesh;

			if (type === 'face' && faceNormal) {
				return (originalMesh as Mesh).material = HelperFunctions.bufferMaterialMap({
					...mesh?.userData?.object?.mesh || {},
					colour: '#AA8605',
				});
			}

			// Check if there is a panelId, we can just select that one then
			if (id) {
				mesh = this.findNameInGroups(id, this.engine.scene.children);
			}

			//@ts-ignore
			if (article && !mesh) {
				mesh = this.findArticleInGroups(
					article,
					this.engine.scene.children
				);
			}

			//@ts-ignore
			if (articleZone && !mesh) {
				mesh = this.findArticleZoneInGroups(
					articleZone,
					this.engine.scene.children
				);
			}

			//@ts-ignore
			if (!mesh) {
				return;
			}

			const ignoreHighlightIds = [mesh.id, ...cleanedItems.reduce((acc, { id }) => {
				if (!id) {
					return acc;
				}

				const foundMesh = this.findNameInGroups(id, this.engine.scene.children);
				if (!foundMesh) {
					return acc;
				}

				return [...acc, foundMesh?.id]
			}, [])];

			this.highlightChildren(mesh.parent!.children, isolatedItem?.id, this.editorMode === EditorMode.DEFAULT ? undefined : EDITOR_STATE_COLOURS.ARTICLE_ZONE_SELECTION_CLICKABLE_ZONES, ignoreHighlightIds); // Apply "green highlight"
			this.applyHighlight(mesh, isolatedItem?.id, this.editorMode === EditorMode.DEFAULT ? '#AA8605' : EDITOR_STATE_COLOURS.ARTICLE_ZONE_SELECTION_SELECTED_ZONES); // Apply "yellow highlight"
		});
	}

	private highlightChildren = (children: Object3D[], isolatedItemId?: string, customColour?: string, skipIds: any[] = []): void => {
		children.forEach((child) => {
			if (skipIds.includes(child.id)) {
				return;
			}

			this.highlightChildren(child.children, isolatedItemId, customColour);
			this.applyHighlight(child, isolatedItemId, customColour)
		})
	}

	private applyHighlight = (child: Object3D, isolatedItemId?: string, customColour?: string): void => {
		const hasCustomisation = !!(child?.userData?.object?.customisations || []).length;
		const shouldBeIsolated = (isolatedItemId && isolatedItemId !== child?.userData?.object?.id);

		if (!child.userData.highlighted) {
			// If the material was not highlighted, we can assume the current material is the original non-highlighted one.
			child.userData.originalMaterial = (child as Mesh).material;
		}

		if (hasCustomisation) {
			child.userData.highlighted = true;
			(child as Mesh).material = HelperFunctions.customisedMaterialMap({
				...child?.userData?.object?.mesh || {},
				...(shouldBeIsolated && { opacity: 0.15, transparent: true }),
				colour: customColour || '#175832',
			});

			return;
		}

		child.userData.highlighted = true;
		(child as Mesh).material = HelperFunctions.bufferMaterialMap({
			...child?.userData?.object?.mesh,
			...(shouldBeIsolated && { opacity: 0.15, transparent: true }),
			colour: customColour || '#175832',
		});
	}

	public findIdInScene(name: string): Mesh {
		return this.findNameInGroups(name, this.engine.scene.children);
	}

	public findNameInGroups(name: string, children: any[]): Mesh {
		return children.reduce((acc, group) => {
			if (acc) {
				return acc;
			}

			if (group.name === name) {
				return group;
			}

			return this.findNameInGroups(name, group.children || []);
		}, null);
	}

	private findArticleInGroups(article: IArticle, children: any[]): Mesh {
		return children.reduce((acc, group) => {
			if (acc) {
				return acc;
			}

			if (group?.userData?.object?.article?.id === article.id) {
				return group;
			}

			return this.findArticleInGroups(article, group.children || []);
		}, null);
	}

	private findDoors(children: any[]): Mesh[] {
		return children.reduce((acc, group) => {
			const childrenDoors = this.findDoors(group.children || []);

			if ([PanelType.FRONT, PanelType.DRAWER_FRONT].includes(group?.userData?.object?.panelType)) {
				return [...acc, ...childrenDoors, group];
			}

			return [...acc, ...childrenDoors]
		}, []);
	}

	private findArticleZoneInGroups(articleZone: IArticleZone, children: any[]): Mesh {
		return children.reduce((acc, group) => {
			if (acc) {
				return acc;
			}

			if (group?.userData?.object?.articleZone?.id === articleZone.id) {
				return group;
			}

			return this.findArticleZoneInGroups(articleZone, group.children || []);
		}, null);
	}

	addObj(url: string, details) {
		this.loader.loadFile(
			url,
			details,
			this.engine.scene,
			//@ts-ignore
			(object, details, parent) => {
				//// Set transformations
				object.position.set(
					details.position.x,
					details.position.y,
					details.position.z
				);
				object.rotation.set(
					details.rotation.x,
					details.rotation.y,
					details.rotation.z
				);
				object.scale.set(
					details.scale.x,
					details.scale.y,
					details.scale.z
				);
				parent.add(object);
			}
		);
	}

	addCubeCutout(details: {
		width: number;
		height: number;
		depth: number;
		position: { x: number; y: number; z: number };
		rotation: { x: number; y: number; z: number };
	}) {
		const cube = this.cutoutGenerator.buildCube(
			details.width,
			details.height,
			details.depth,
			details.position,
			details.rotation
		);
		cube.userData.type = 'cutout';
		cube.userData.lockedAxis = 'z';
		cube.geometry.computeBoundingBox();
		this.engine.scene.add(cube);
		this.movableObjects.push(cube);
	}

	addMovementEvents() {
		const movementFunctions = [
			{
				funct: this.pointerdown,
				eventName: 'pointerdown',
				editor: this,
			},
			{
				funct: this.pointerup,
				eventName: 'pointerup',
				editor: this,
			},
			{
				funct: this.dragObject,
				eventName: 'pointermove',
				editor: this,
			},
		];
		this.movementManager.addMovementEvents(movementFunctions);
	}

	removeEvents() {
		this.movementManager?.removeEvents();
	}

	selectCutout(isCutoutSelected: boolean, object?: Mesh) {
		this.engine.controls.enableRotate = !isCutoutSelected;
		if (!object) return;
		this.activeCutout = object;
		this.pIntersect.copy(this.selectionManager.lastIntersection.point);
		this.plane.setFromNormalAndCoplanarPoint(
			this.pNormal[object.userData.lockedAxis],
			this.pIntersect
		);
		this.shift.subVectors(
			object.position,
			this.selectionManager.lastIntersection.point
		);
	}

	pointerdown() {
		this.isPointerdown = true;
	}

	pointerup() {
		this.isPointerdown = false;
		this.activeCutout = null;
	}

	//// Movement function that disables orbit controls
	dragObject(evt) {
		//// Check to see if a cutout was last selected
		if (this.engine.controls.enableRotate) return;
		if (!this.isPointerdown || !this.activeCutout) return;
		this.movementManager.dragObject(evt);
		//// Move alongside the locked plane axis
		this.movementManager.moveObject(
			this.plane,
			this.planeIntersect,
			this.shift,
			this.activeCutout
		);

		this.engine.needToRender();
	}

	checkCutout(object) {
		return object.userData.type == 'cutout';
	}
}
